import { useReducer, useEffect, useRef, useState, useCallback } from 'react';

import {
  Typography as T,
  Grid,
  Inputs,
  Button,
  Modal,
} from '../../../components';
import { LandingPage } from '../../../api-calls';

import validate from '../../../validation/schemas/edit-landing-page';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../theme';

const { Col, Row } = Grid;

const initialState = {
  headline: '',
  subtitle: '',
  instructions: '',
  isThisHelperForMe: '',
  getStarted: '',
  httpError: '',
  validationErrs: {},
  loading: false,
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const EditLandingPage = () => {
  const submitAttempt = useRef(false);
  const [state, setState] = useReducer(reducer, initialState);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    headline,
    subtitle,
    instructions,
    isThisHelperForMe,
    getStarted,
    httpError,
    validationErrs,
    loading,
  } = state;

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headline, subtitle, instructions, isThisHelperForMe, getStarted]);
  const validateForm = useCallback(() => {
    try {
      validate({
        headline,
        subtitle,
        instructions,
        isThisHelperForMe,
        getStarted,
      });
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  }, [headline, subtitle, instructions, isThisHelperForMe, getStarted]);

  const handleUpdate = useCallback(async () => {
    setState({ loading: true });

    const { error } = await LandingPage.updateLandingPageContent({
      body: { headline, subtitle, instructions, isThisHelperForMe, getStarted },
    });
    setState({ loading: false });
    if (error) {
      setState({ httpError: error.message });
    } else {
      //  show modal here
      setIsModalVisible(true);
    }
  }, [headline, subtitle, instructions, isThisHelperForMe, getStarted]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      submitAttempt.current = true;

      const isValid = validateForm();
      if (isValid) {
        handleUpdate();
      }
    },
    [handleUpdate, validateForm]
  );

  const getLandingPageData = useCallback(async () => {
    setState({ loading: false });

    const { error, data } = await LandingPage.getLandingPageContent({
      forPublic: false,
    });

    setState({ loading: false });
    if (error) {
      setState({ httpError: error.message });
    } else {
      const {
        headline,
        subtitle,
        instructions,
        isThisHelperForMe,
        getStarted,
      } = data;
      setState({
        headline,
        subtitle,
        instructions,
        isThisHelperForMe,
        getStarted,
      });
    }
  }, []);

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.mobile})`,
  });

  useEffect(() => {
    getLandingPageData();
  }, [getLandingPageData]);

  return (
    <>
      <Row jc="space-between" mb={7}>
        <Col w={[4, 12, 7]}>
          <T.H1 mtM="5" style={{ width: '100%' }}>
            Edit Landing Page Content
          </T.H1>
        </Col>
      </Row>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col w={[4, 12, 6]}>
            <Inputs.Textarea
              value={headline}
              label="Headline"
              handleChange={(value) => setState({ headline: value })}
              error={validationErrs.headline}
              autoSize={{ minRows: 6, maxRows: 15 }}
            />
          </Col>
          <Col w={[4, 12, 6]} mtM="5">
            <Inputs.Textarea
              value={subtitle}
              label="Subtitle"
              handleChange={(value) => setState({ subtitle: value })}
              error={validationErrs.subtitle}
              autoSize={{ minRows: 6, maxRows: 15 }}
            />
          </Col>
        </Row>
        <Row>
          <Col w={[12, 12, 12]} style={{ maxWidth: '100%' }}>
            <Inputs.Textarea
              value={instructions}
              label="Instructions"
              handleChange={(value) => setState({ instructions: value })}
              error={validationErrs.instructions}
              m={{ mt: '6' }}
              autoSize={{ minRows: 2, maxRows: 15 }}
            />
          </Col>
        </Row>
        <Row>
          <Col w={[12, 12, 12]} style={{ maxWidth: '100%' }}>
            <Inputs.Textarea
              value={isThisHelperForMe}
              label="Is this helper for me?"
              handleChange={(value) => setState({ isThisHelperForMe: value })}
              error={validationErrs.isThisHelperForMe}
              m={{ mt: '6' }}
              autoSize={{ minRows: 2, maxRows: 15 }}
            />
          </Col>
        </Row>
        <Row>
          <Col w={[12, 12, 12]} style={{ maxWidth: '100%' }}>
            <Inputs.Textarea
              value={getStarted}
              label="Let's get started"
              handleChange={(value) => setState({ getStarted: value })}
              error={validationErrs.getStarted}
              m={{ mt: '6' }}
              autoSize={{ minRows: 2, maxRows: 15 }}
            />
          </Col>
        </Row>
        <Row
          mt="7"
          mtT="6"
          style={{ flex: Number(isMobile), alignItems: 'flex-end' }}
        >
          <Col w={[4, 11, 6]} style={{ alignItems: 'flex-end' }}>
            {httpError && (
              <T.P mb="2" color="error">
                {httpError}
              </T.P>
            )}
            <Button
              variant="primary"
              disabled={false}
              loading={loading}
              text="Save changes"
              type="submit"
            />
          </Col>
        </Row>
      </form>
      <Modal
        visible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        type={'updateSuccess'}
        title={'Updated'}
        description={'Changes successfully updated'}
        btnText="Okay"
      />
    </>
  );
};

export default EditLandingPage;
