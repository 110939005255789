import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';

export const Button = styled.button`
  width: ${({ isFontLarge }) => (isFontLarge ? '115px' : '90px')};
  height: 45px;
  background: ${({ theme }) => theme.colors.primaryDark};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 12px 12px 0px 0px;
  transform: rotate(-90deg);
  position: fixed;
  right: ${({ position }) => position.right || 0};
  top: ${({ position }) => position.top || '65%'};
  left: ${({ position }) => position.left && position.left};
  bottom: ${({ position }) => position.bottom && position.bottom};
  padding: 0;
  margin: 0;
  margin-inline-start: ${({ mr }) => mr || '-25px'};
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  right: -24px;
  right: ${({ isFontLarge }) => (isFontLarge ? '-35px' : '-24px')};
`;

export const ModalWrapper = styled.section`
  ${({ theme }) => theme.media.mobile} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: ${({ theme }) => theme.colors.neutralSurface};
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: ${({ theme: { spacings } }) => spacings[6]};
    }
  }
  pointer-events: all;
`;

export const Modal = styled.section`
  z-index: 100;
  position: fixed;
  right: 0;
  bottom: ${({ theme: { spacings } }) => spacings[6]};
  margin-right: ${({ theme: { spacings } }) => spacings[6]};
  margin-left: ${({ theme: { spacings } }) => spacings[6]};
  max-width: ${({ adminHelp, isFontLarge }) =>
    adminHelp ? '375px' : isFontLarge ? '800px' : '450px'};
  overflow: hidden;
  box-sizing: border-box;
  background: ${({ theme: { colors } }) => colors.white};
  box-shadow: ${({ theme: { shadows } }) => shadows.elevation1};
  border-radius: ${({ theme: { borders } }) => borders.radiusLarge};
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    bottom: auto;
    position: relative;
    margin: 0 auto;
  }
`;

export const Header = styled.div`
  width: 100%;
  background: ${({ theme: { colors } }) => colors.primaryDark};
  display: flex;
  justify-content: space-between;
  padding: ${({ theme: { spacings } }) => spacings[4]};
  border-top-left-radius: ${({ theme: { borders } }) => borders.radiusLarge};
  border-top-right-radius: ${({ theme: { borders } }) => borders.radiusLarge};
`;

export const CloseButton = styled.button`
  && {
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer !important;
  }
`;

export const Content = styled.div`
  padding: ${({ theme: { spacings } }) => spacings[4]};
  width: 100%;
`;

export const ContactItem = styled.div`
  ${setMargin};
  display: grid;
  grid-template-columns: 65px 1fr;
  width: 100%;
`;

export const AdminHelpWrapper = styled.div`
  margin-bottom: 31px;
  padding-inline-start: 3px;
`;
