import styled from '@emotion/styled';
import ReactPlayer from 'react-player';

export const PlayerWrapper = styled.div`
  width: auto !important;
  min-width: 0;
  aspect-ratio: 16/9;
  display: ${({ width }) => (width ? 'inline-block' : 'inherit')};
`;

export const StyledPlayer = styled(ReactPlayer)`
  position: relative;
  top: 0;
  left: 0;
`;
