const BackArrow = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.10992 10.9531L18.5981 10.9531C19.3724 10.9531 20 11.5926 20 12.3815C20 13.1704 19.3724 13.8099 18.5981 13.8099L4.43527 13.8099L6.13805 15.5683C6.68185 16.1298 6.67593 17.0342 6.12483 17.5883C5.57372 18.1424 4.68613 18.1364 4.14233 17.5749L0.40401 13.7146C-0.137228 13.1557 -0.134285 12.2564 0.410598 11.7012L4.14892 7.89206C4.69638 7.33422 5.58399 7.33422 6.13146 7.89206C6.67892 8.4499 6.67892 9.35433 6.13146 9.91217L5.10992 10.9531Z"
      fill={color || '#1A202B'}
    />
  </svg>
);

export default BackArrow;
