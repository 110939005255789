const InProgress = ({ width, height, color, ...props }) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#a)">
      <mask
        id="b"
        width="20"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
      >
        <path fill="#fff" d="M19.561 0H.556v20H19.56V0Z" />
      </mask>
      <g fill="#DC4405" mask="url(#b)">
        <path d="M16.323 4.346c1.172.162 2.264-.687 2.425-1.9.162-1.173-.687-2.264-1.9-2.426-1.213-.162-2.264.687-2.426 1.9a2.132 2.132 0 0 0 1.9 2.426ZM6.215 4.87c.526-.646 1.051-1.334 1.577-1.98.202-.243.445-.364.768-.324 1.456.162 2.911.323 4.326.526.283.04.566.161.768.323.728.606 1.456 1.253 2.183 1.86.405.323.566.768.486 1.293-.081.688-.162 1.415-.243 2.143 0 .081 0 .162-.04.283.08 0 .161.04.242.04.809.081 1.577.203 2.385.284.566.08.93.485.89 1.01-.081.526-.526.89-1.092.809-1.293-.162-2.547-.283-3.84-.445-.405-.04-.566-.283-.526-.687.08-.607.121-1.213.202-1.779v-.121c-.728.849-1.415 1.698-2.102 2.547.08.08.121.121.202.161a75.123 75.123 0 0 0 2.102 1.658c.324.283.485.607.404 1.01-.202 1.82-.444 3.68-.646 5.5-.081.686-.566 1.09-1.254 1.01-.687-.081-1.05-.405-1.132-.97v-.324c.162-1.536.364-3.113.526-4.65 0-.12.04-.201-.08-.323a67.74 67.74 0 0 1-2.063-1.536l-.808-.849c-.122-.121-1.415-1.536-.324-2.91.89-1.092 1.739-2.224 2.628-3.316.04-.08.121-.162.202-.243-.08 0-.121-.04-.202-.04-.89-.121-1.779-.202-2.628-.323-.162 0-.242.04-.323.161-.364.485-.728.93-1.132 1.415-.364.445-.93.566-1.375.202-.404-.363-.445-.93-.08-1.415Zm-4.204 7.156c.04-.525.283-.97.808-1.172.324-.162.688-.08 1.011.08.85.486 1.698.93 2.547 1.416.08.04.162.08.243.161l.12-.242c.486-.85.446-.728.89-1.617 0-.04.202-.324.324-.566.121.768.566 1.415.93 1.779l.525.525c-.485.89-1.01 1.82-1.496 2.668-.283.526-.606.607-1.132.324-1.374-.768-2.79-1.536-4.164-2.304-.404-.243-.687-.567-.606-1.052ZM3.943 19.175H.96a.406.406 0 0 0-.404.404c0 .223.181.405.404.405h2.983a.405.405 0 0 0 .405-.405.406.406 0 0 0-.405-.404Z" />
        <path d="M6.228 19.175H3.244a.406.406 0 0 0-.404.404c0 .223.182.405.404.405h2.984a.405.405 0 0 0 .404-.405.406.406 0 0 0-.404-.404Z" />
        <path d="M7.59 19.175H4.606a.406.406 0 0 0-.404.404c0 .223.182.405.404.405H7.59a.406.406 0 0 0 .404-.405.406.406 0 0 0-.404-.404ZM7.372 17.332H3.948c-.295 0-.538.182-.538.404 0 .223.239.404.538.404h3.424c.295 0 .538-.181.538-.404 0-.222-.239-.404-.538-.404Z" />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default InProgress;
