const SQ = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_54_3951"
      maskUnits="userSpaceOnUse"
      x="0"
      y="3"
      width="24"
      height="18"
    >
      <path
        d="M0 5.42999C0 4.32542 0.895431 3.42999 2 3.42999H22C23.1046 3.42999 24 4.32542 24 5.42999V18.5729C24 19.6774 23.1046 20.5728 22 20.5728H2C0.89543 20.5728 0 19.6774 0 18.5728V5.42999Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_54_3951)">
      <path d="M0 3.42999H24V20.5728H0V3.42999Z" fill="#EE343C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6726 7.24514C10.4479 7.02043 10.0958 6.98557 9.83138 7.16184L9.52769 7.3643C9.10178 7.64825 9.14151 8.28637 9.59935 8.51529L10.489 8.96013C10.7149 9.07306 10.8576 9.3039 10.8576 9.55642V9.62058C10.8576 9.98877 10.5591 10.2873 10.1909 10.2873H9.87208C9.76858 10.2873 9.66651 10.2632 9.57394 10.2169L7.85877 9.35928C7.60211 9.23095 7.29212 9.28126 7.08922 9.48416L6.94017 9.63321C6.61841 9.95497 6.70643 10.4974 7.11343 10.7009L7.80434 11.0464C8.14633 11.2173 8.176 11.694 7.85786 11.9061C7.48873 12.1522 7.6014 12.7217 8.03643 12.8087L8.51809 12.9051C8.98056 12.9976 9.11575 13.5936 8.73846 13.8766L8.30723 14.2C8.0259 14.411 8.17513 14.8587 8.5268 14.8587C8.55672 14.8587 8.58652 14.855 8.61555 14.8478L9.69707 14.5774C10.1072 14.4749 10.4285 14.9308 10.194 15.2825C9.98914 15.5899 10.2095 16.0015 10.5788 16.0015H11.0789C11.288 16.0015 11.4736 16.1353 11.5397 16.3336C11.6873 16.7764 12.3136 16.7764 12.4612 16.3336C12.5273 16.1353 12.7128 16.0015 12.9219 16.0015H13.422C13.7914 16.0015 14.0117 15.5899 13.8068 15.2825C13.5723 14.9308 13.8937 14.4749 14.3038 14.5774L15.3853 14.8478C15.4143 14.855 15.4441 14.8587 15.474 14.8587C15.8257 14.8587 15.9749 14.411 15.6936 14.2L15.2624 13.8766C14.8851 13.5936 15.0203 12.9976 15.4827 12.9051L15.9644 12.8087C16.3994 12.7217 16.5121 12.1522 16.143 11.9061C15.8248 11.694 15.8545 11.2173 16.1965 11.0464L16.8874 10.7009C17.2944 10.4974 17.3824 9.95497 17.0607 9.63321L16.9116 9.48416C16.7087 9.28126 16.3987 9.23095 16.1421 9.35928L14.4269 10.2169C14.3343 10.2632 14.2322 10.2873 14.1288 10.2873H13.8099C13.4418 10.2873 13.1433 9.98877 13.1433 9.62058V9.55642C13.1433 9.3039 13.2856 9.07322 13.5115 8.96029C13.7898 8.82113 14.1067 8.66269 14.4016 8.51525C14.8594 8.28633 14.8991 7.64825 14.4731 7.3643L14.1694 7.16184C13.905 6.98557 13.553 7.02043 13.3282 7.24514L12.4718 8.10156C12.2115 8.36191 11.7894 8.36191 11.529 8.10156L10.6726 7.24514Z"
        fill="#262626"
      />
    </g>
  </svg>
);

export default SQ;
