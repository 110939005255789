const getStyles = (status) => {
  if (status === 'completed') {
    return {
      bgColor: 'neutralLight',
      bannerColor: 'tertiaryMain',
      icon: 'circleTick',
      color: 'tertiaryMain',
    };
  }

  if (status === 'inProgress') {
    return {
      bgColor: 'primaryLight',
      bannerColor: 'primaryDark',
      icon: 'inProgress',
      color: 'primaryMain',
    };
  }

  return {
    bgColor: 'secondaryLight',
    bannerColor: 'secondaryMain',
    icon: 'todo',
    color: 'secondaryMain',
  };
};

export default getStyles;
