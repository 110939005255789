const Upload = ({ width, height, color, ...props }) => (
  <svg
    width={width || '34'}
    height={width || '34'}
    viewBox="0 0 34 34"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.18359 20.123V29.0141C3.18359 29.7195 3.75749 30.2934 4.46289 30.2934H29.5371C30.2425 30.2934 30.8164 29.7195 30.8164 29.0141V20.123H33.375V29.0141C33.375 31.1303 31.6533 32.852 29.5371 32.852H4.46289C2.34668 32.852 0.625 31.1303 0.625 29.0141V20.123H3.18359Z"
      fill={color}
    />
    <path
      d="M17.0004 0.14798L24.9502 8.09778L23.141 9.90697L18.2797 5.04564V24.9845H15.7211V5.04564L10.8598 9.90697L9.05059 8.09778L17.0004 0.14798Z"
      fill={color}
    />
  </svg>
);

export default Upload;
