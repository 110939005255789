import { fields, createSchema, validate as _validate } from '..';

const {
  requiredText,
  videoLinkType,
  optionalArrayOfOptionalString,
  requiredStageType,
  optionalText,
} = fields;

const volunteer = createSchema({
  headline: requiredText,
  subtitle: requiredText,
  instructions: requiredText,
  topTip: optionalText,
  otherTips: optionalArrayOfOptionalString,
  videoLink: videoLinkType,
  stageType: requiredStageType,
});

const validate = (data) => _validate(volunteer, data);

export default validate;
