import { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import {
  Button,
  Grid,
  HelpButton,
  Header,
  ContactUs,
  VideoPlayer,
  TextWithIcon,
  Icon,
  Typography as T,
} from '../../components';
import { SingleTip } from '../../components/Cards/Tips';
import { useSteps } from '../../context/steps';
import { useStages } from '../../context/stages';
import { common, navRoutes as n, types } from '../../constants';
import * as Steps from '../../api-calls/steps';
import * as S from './style';

import { useLanguage } from '../../helpers';
import { usePublicOrg } from '../../context/public-org';

import StepDefault from './StepDefault';
import StepQuestion from './StepQuestion';

const { Row, Col } = Grid;

const Step = () => {
  const [step, setStep] = useState([]);
  const [stuck, setStuck] = useState(false);
  const { publicOrg } = usePublicOrg();
  const params = useParams();
  const navigate = useNavigate();
  const { steps, checkUncheckItem, markAsComplete } = useSteps();
  const { stages, markSuccessAsShown } = useStages();
  const { t, i18n } = useTranslation();
  const { lng } = useLanguage();

  const { uniqueSlug } = publicOrg;

  const stepId = step?.id;
  const stageId = step?.stageId;
  const dir = useMemo(() => i18n.dir(), [i18n, lng]); // Don't drop lng dep

  const stepFromStorage = steps.find((step) => step.id === Number(params.id));
  const localStorageStage = stages.find((stage) => stage.id === step?.stageId);

  const stageSteps = useMemo(
    () => steps.filter((_step) => _step?.stageId === stageId),
    [stageId, steps]
  );

  useEffect(() => {
    const isCompletedStage = stageSteps.every(
      (step) => step.isCompleted === true
    );
    if (
      isCompletedStage &&
      !!localStorageStage &&
      !localStorageStage?.isCompleted &&
      !localStorageStage?.successShown
    ) {
      navigate(`/success/${stageId}`);
    }
  }, [localStorageStage, markSuccessAsShown, stageSteps, stageId, navigate]);

  useEffect(() => {
    const getSteps = async () => {
      const hideMessage = message.loading('Loading...', 0);

      const { data, error } = await Steps.getStepById({
        id: params.id,
        lng,
        forPublic: true,
      });

      hideMessage();
      if (error) {
        navigate(n.GENERAL.NOT_FOUND);
      }
      setStep(data);
    };
    getSteps();
  }, [lng, navigate, params.id]);

  if (!step) {
    navigate(n.GENERAL.NOT_FOUND);
    return null;
  }

  const checkItem = (index, key) => {
    const foundItem = stepFromStorage?.[key]?.[index];
    return foundItem?.isChecked;
  };

  return (
    <S.Container>
      <S.InnerContainer>
        <Header
          uniqueSlug={uniqueSlug}
          publicOrg={publicOrg}
          title={step?.pageTitle || step?.title}
        />
      </S.InnerContainer>
      <S.InnerContainer thin dir={dir}>
        <Row>
          <Col w={[4, 12, 12]}>
            <S.StyledText>
              <div
                dangerouslySetInnerHTML={{
                  __html: (step?.pageDescription || step?.description)?.replace(
                    /\n/g,
                    '<br>'
                  ),
                }}
              />
            </S.StyledText>
            {step?.topTip && (
              <SingleTip
                mt={6}
                tip={step.topTip}
                icon="bulb"
                bgColor="secondaryLight"
                borderColor="secondaryMain"
                textColor="secondaryMain"
                iconColor="secondaryMain"
              />
            )}
          </Col>
        </Row>

        {step?.videoLink && (
          <S.InnerContainer>
            <VideoPlayer videoUrl={step.videoLink} />
          </S.InnerContainer>
        )}

        {step?.stepType === types.stepTypes.DEFAULT && (
          <StepDefault
            step={step}
            checkUncheckItem={checkUncheckItem}
            checkItem={checkItem}
          />
        )}

        {step?.stepType === types.stepTypes.QUESTION && (
          <StepQuestion
            step={step}
            checkUncheckItem={checkUncheckItem}
            checkItem={checkItem}
          />
        )}

        {step.resources?.length > 0 && (
          <Row mt="6" mtM="6">
            <Col w={[4, 12, 12]} ai="flex-start">
              <S.SectionHeader mb="5">
                <Icon
                  icon="checklist2"
                  width={24}
                  height={24}
                  color="primaryMain"
                  mr="2"
                />
                <T.P size="xl" weight="bold" color="neutralMain">
                  {t(
                    'common.heading.resources.title',
                    common.heading.resources.title
                  )}
                </T.P>
              </S.SectionHeader>
              <Row inner>
                {step.resources?.map((item) => (
                  <Col w={[4, 12, 6]} key={item}>
                    <S.ResourceWrapper>
                      <TextWithIcon
                        text={item.title}
                        to={item.url}
                        external
                        underline
                        weight="medium"
                        iconProps={{
                          icon: 'open',
                          color: 'primaryMain',
                        }}
                      />
                    </S.ResourceWrapper>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        )}

        {step.otherTips &&
          step?.otherTips?.map((item) => (
            <Row key={item}>
              <Col w={[4, 12, 12]} mt="6">
                <SingleTip
                  tip={item}
                  icon="bulb"
                  bgColor="secondaryLight"
                  borderColor="secondaryMain"
                  textColor="secondaryMain"
                  iconColor="secondaryMain"
                />
              </Col>
            </Row>
          ))}

        <Row>
          <Col w={[4, 12, 6]} mt="7" mx="auto">
            <Button
              variant="secondary"
              text={t(
                'common.buttons.markAsComplete',
                common.buttons.markAsComplete
              )}
              handleClick={() => {
                markAsComplete(step.id);

                const areOtherStepsCompleted = stageSteps
                  .filter((step) => step.id !== stepId)
                  .every((step) => !!step.isCompleted);

                if (areOtherStepsCompleted) {
                  navigate(`/success/${stageId}`);
                } else {
                  navigate(
                    publicOrg?.uniqueSlug
                      ? n.GENERAL.STAGE_ORG.replace(
                          ':uniqueSlug',
                          publicOrg.uniqueSlug
                        ).replace(':id', step.stageId)
                      : n.GENERAL.STAGE.replace(':id', step.stageId)
                  );
                }
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 12, 12]} mt="6">
            <ContactUs onClick={() => setStuck(true)} />
          </Col>
        </Row>
      </S.InnerContainer>
      <HelpButton parentState={stuck} parentFunc={() => setStuck(false)} />
    </S.Container>
  );
};

export default Step;
