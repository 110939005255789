import { memo } from 'react';
import * as S from './style';

function isYouTubeOrVimeo(url) {
  if (/^https?:\/\/(www\.)?youtube\.com\/|youtu\.be\//.test(url)) {
    return 'YouTube';
  } else if (/^https?:\/\/(www\.)?vimeo\.com/.test(url)) {
    return 'Vimeo';
  } else {
    return false;
  }
}

const VideoPlayer = ({ videoUrl, width, ...props }) => {
  try {
    const validUrl = isYouTubeOrVimeo(videoUrl);
    if (!validUrl) {
      throw new Error('Video url provided is not YouTube or Vimeo');
    }

    return (
      <S.PlayerWrapper width={width}>
        <S.StyledPlayer
          url={videoUrl}
          controls={validUrl === 'Vimeo'}
          height={'100%'}
          width={width || '100%'}
          {...props}
        />
      </S.PlayerWrapper>
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return <></>;
  }
};

export default memo(VideoPlayer);
