import styled from '@emotion/styled';
import { Typography as T } from '../../components';
import setMargin from '../../helpers/set-margin';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const InnerContainer = styled.div`
  ${setMargin}
  max-width: 720px;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: ${({ thin, theme }) => (thin ? theme.spacings[7] : 0)};
  padding-right: ${({ thin, theme }) => (thin ? theme.spacings[7] : 0)};
`;

export const InlineParagraph = styled(T.P)`
  display: inline;
`;
