import setColor from '../helpers/set-color-variations';
import formatColor from '../helpers/format-color';

const primaryMain = { h: 18, s: 0.96, l: 0.44 };
const secondaryMain = { h: 206, s: 0.79, l: 0.39 };

export default {
  primaryMain: formatColor(primaryMain),
  primaryMid: formatColor(setColor('primary', primaryMain).mid),
  primaryLight: formatColor(setColor('primary', primaryMain).light),
  primaryDark: formatColor(setColor('primary', primaryMain).dark),
  secondaryMain: formatColor(secondaryMain),
  secondaryMid: formatColor(setColor('secondary', secondaryMain).mid),
  secondaryLight: formatColor(setColor('secondary', secondaryMain).light),
  secondaryDark: formatColor(setColor('secondary', secondaryMain).dark),
  tertiaryMain: 'hsla(187, 100%, 17%, 1)',
  tertiaryMid: 'hsla(187, 40%, 65%, 1)',
  neutralMain: 'hsla(219, 25%, 14%, 1)',
  neutralGrey: 'hsla(186, 12%, 30%, 1)',
  neutralLight: 'hsla(187, 14%, 81%, 1)',
  neutralSurface: 'hsla(187, 20%, 95%, 1)',
  white: 'hsl(0, 0%, 100%)',
  modalBackground: 'hsl(215, 61%, 37%)',
  error: 'hsla(18, 96%, 39%, 1)',
  borderPrimary: formatColor(primaryMain),
  borderSecondary: 'hsl(195, 8%, 38%)',
  primaryMainObj: primaryMain,
  secondaryMainObj: secondaryMain,
  facebookBlue: 'hsla(214, 89%, 52%, 1)',
  whatsappGreen: 'hsla(142, 70%, 49%, 1)',
  irlenBlue: '#96ADFC',
  irlenGreen: '#A8F29A',
  irlenYellow: '#EDDD6E',
  irlenRed: '#E0A6AA',
};
