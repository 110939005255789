import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Grid as G } from '../../components';
import { Stage } from '../../components/Cards';
import ContactUs from '../../components/ContactUs/index';
import HelpButton from '../../components/HelpButton';
import Image from '../../components/Image';
import * as T from '../../components/Typography';
import { common } from '../../constants';
import { stageTypes } from '../../constants/data-types';
import { useStages } from '../../context/stages';
import { usePublicOrg } from './../../context/public-org';
import LandingContent from './LandingContent';
import * as S from './style';

const Home = () => {
  const { publicOrg } = usePublicOrg();
  const { uniqueSlug } = publicOrg;
  const { token } = useParams();

  const { t } = useTranslation();

  const { stages } = useStages();

  const [stuck, setStuck] = useState(false);

  const nextStageRef = createRef(null);

  const getStageStatus = useCallback((stage) => {
    if (stage.isCompleted) {
      return 'completed';
    }
    if (stage.isInProgress) {
      return 'inProgress';
    }
    return 'notStarted';
  }, []);

  const stagesWithoutAfterClaiming = useMemo(
    () =>
      stages.filter((stage) => stage.stageType !== stageTypes.AFTER_CLAIMING),
    [stages]
  );

  const afterClaimingStages = useMemo(
    () =>
      stages.filter((stage) => stage.stageType === stageTypes.AFTER_CLAIMING),
    [stages]
  );

  const nextStage = useMemo(
    () => stages.filter((stage) => !stage.isCompleted),
    [stages]
  );

  const nextStageId = nextStage[0]?.id;

  useEffect(() => {
    if (token === 'success') {
      setTimeout(() => {
        nextStageRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 350);
    }
  }, [nextStageRef, token]);

  return (
    <S.Container>
      <LandingContent uniqueSlug={uniqueSlug} />

      <S.StagesContainer>
        <S.StagesSection>
          {stagesWithoutAfterClaiming.map(
            ({ headline, subtitle, id, ...stage }) => {
              const status = getStageStatus(stage);
              const stageProps = {
                id: id,
                to: `/stages/${id}`,
                title: headline,
                text: subtitle,
                status: status,
              };

              if (nextStageId === id) {
                stageProps.ref = nextStageRef;
              }

              return (
                <S.StageWrapper>
                  <Stage {...stageProps} />
                </S.StageWrapper>
              );
            }
          )}
        </S.StagesSection>

        <S.HorizontalDivider />

        <S.StagesSection>
          {afterClaimingStages.map(({ headline, subtitle, id, ...stage }) => {
            const status = getStageStatus(stage);
            const stageProps = {
              id: id,
              to: `/stages/${id}`,
              title: headline,
              text: subtitle,
              status: status,
            };

            if (nextStageId === id) {
              stageProps.ref = nextStageRef;
            }

            return (
              <S.StageWrapper>
                <Stage {...stageProps} />
              </S.StageWrapper>
            );
          })}
        </S.StagesSection>
      </S.StagesContainer>

      <ContactUs onClick={() => setStuck(true)} />

      <HelpButton parentState={stuck} parentFunc={() => setStuck(false)} />
      <S.Section mt={4}>
        <Image
          image="bgetLogo"
          width={'300px'}
          customStyle={{ paddingTop: '40px', alignItems: 'center' }}
        />

        <S.Section mw="330px" mt="40px">
          <G.Row>
            <G.Col w={[4, 6, 6]} jc="center">
              <T.P
                strong
                align="center"
                color="#282E97"
                style={{
                  padding: 0,
                }}
              >
                {t(
                  'common.section.homePage.widthAdditionalSupportFrom',
                  common.section.homePage.widthAdditionalSupportFrom
                )}
              </T.P>
            </G.Col>
            <G.Col w={[4, 6, 6]} jc="center">
              <Image
                image="mallinckrodtLogo"
                width={'150px'}
                customStyle={{ alignItems: 'center' }}
              />
            </G.Col>
          </G.Row>
        </S.Section>
      </S.Section>
    </S.Container>
  );
};

export default Home;
