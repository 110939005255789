import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import * as S from './style';
import * as T from '../Typography';
import TextWithIcon from '../TextWithIcon';
import { useTranslation } from 'react-i18next';
import { common } from '../../constants';
import { useAccessibility } from '../../context/accessibility';

const Card = forwardRef(
  (
    {
      variant = 'primary',
      direction = 'right',
      content,
      children,
      title,
      description,
      isCompleted,
      isInProgress,
      handleClick,
      isJustCompletedOne,
      to = '/',
      isOptional,
      loadingSteps,
      ...props
    },
    ref
  ) => {
    const { isFontLarge } = useAccessibility();
    const { t } = useTranslation();

    const completedStyles = {
      bgColor: 'neutralLight',
      bannerColor: 'tertiaryMain',
      icon: 'circleTick',
      color: 'tertiaryMain',
    };

    const inProgressStyles = {
      bgColor: 'primaryLight',
      bannerColor: 'primaryDark',
      icon: 'inProgress',
      color: 'primaryMain',
    };

    const todoStyles = {
      bgColor: 'secondaryLight',
      bannerColor: 'secondaryMain',
      icon: 'todo',
      color: 'secondaryMain',
    };

    const stylesLookup = {
      completed: completedStyles,
      inProgress: inProgressStyles,
      todo: todoStyles,
    };

    const status = isCompleted
      ? 'completed'
      : isInProgress
      ? 'inProgress'
      : 'todo';

    const styles = stylesLookup[status];

    if (isOptional)
      return (
        <S.Wrapper onClick={handleClick} mb="8" {...props} ref={ref}>
          <S.StyledLink
            to={to}
            style={{ color: 'transparent', width: '100%' }}
            disabled={loadingSteps}
          >
            <S.OptionalContainer>
              <T.H2 color="neutralMain" mb="4">
                {title}
              </T.H2>
              <T.P color="neutralMain" mb="4">
                {description}
              </T.P>
              <TextWithIcon
                text={t('common.buttons.checkHere', common.buttons.checkHere)}
                color="neutralMain"
                iconProps={{
                  color: 'primaryMain',
                  icon: 'forwardArrow',
                }}
                to={to}
              />
            </S.OptionalContainer>
          </S.StyledLink>
        </S.Wrapper>
      );

    return (
      <S.Wrapper
        bgColor={styles.bgColor}
        onClick={handleClick}
        {...props}
        ref={ref}
      >
        <Link
          to={to}
          style={{ color: 'transparent', width: '100%', maxWidth: '720px' }}
          disabled={loadingSteps}
        >
          <S.Container
            direction={direction}
            titleLength={title.length > 32}
            isFontLarge={isFontLarge}
          >
            <S.AngledBanner
              bannerColor={styles.bannerColor}
              direction={direction}
              isFontLarge={isFontLarge}
            >
              <T.P weight="200" color={'white'} ta={direction}>
                {title}
              </T.P>
            </S.AngledBanner>
            <T.P
              size="small"
              mt={2}
              mb={2}
              color="neutralMain"
              style={{
                width: '100%',
              }}
              // ellipsis={{ rows: 3, expandable: true, symbol: ' ' }}
            >
              {description}
            </T.P>

            <TextWithIcon
              mr={2}
              text={
                isCompleted
                  ? t('common.words.completed', common.words.completed)
                  : isInProgress
                  ? t('common.words.inProgress', common.words.inProgress)
                  : t('common.words.todo', common.words.todo)
              }
              textSize="xsmall"
              color={styles.color}
              iconProps={{
                icon: styles.icon,
                color: styles.color,
              }}
            />
          </S.Container>
        </Link>
      </S.Wrapper>
    );
  }
);

export default Card;
