import StepSection from './StepSection';

const DefaultStepSections = ({ state, setState, validationErrs, ...props }) => {
  const {
    thingsYouWillNeed,
    thingsThatMightBeUseful,
    whatHappensInThisStage,
    whatWillHappenNext,
    resources,
  } = state;

  const sections = [
    {
      sectionItems: thingsYouWillNeed,
      sectionTitle: 'Things you will need',
      sectionKey: 'thingsYouWillNeed',
      setState,
      errors: validationErrs.thingsYouWillNeed,
    },
    {
      sectionItems: thingsThatMightBeUseful,
      sectionTitle: 'Things that might be useful',
      sectionKey: 'thingsThatMightBeUseful',
      setState,
      errors: validationErrs.thingsThatMightBeUseful,
    },
    {
      sectionItems: whatHappensInThisStage,
      sectionTitle: 'What happens in this stage',
      sectionKey: 'whatHappensInThisStage',
      setState,
      errors: validationErrs.whatHappensInThisStage,
    },
    {
      sectionItems: whatWillHappenNext,
      sectionTitle: 'What will happen next',
      sectionKey: 'whatWillHappenNext',
      setState,
      errors: validationErrs.whatWillHappenNext,
    },
    {
      sectionItems: resources,
      sectionTitle: 'Resources',
      sectionKey: 'resources',
      setState,
      errors: validationErrs.resources,
      isResourcesLayout: true,
    },
  ];

  return (
    <>
      {sections.map((section) => (
        <StepSection
          key={`DEFAULT_SECTION_${section.sectionKey}`}
          {...section}
          {...props}
        />
      ))}
    </>
  );
};

export default DefaultStepSections;
