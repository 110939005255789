import { SUPER_ADMIN, ADMIN } from '../../constants/nav-routes';
import t from '../../constants/translations';
import R from '../../constants/roles';
import * as S from './style';
import { useAuth } from '../../context/auth';
import { useNavigate } from 'react-router-dom';
import * as T from '../../components/Typography';
import { userStatuses } from '../../constants/data-types';

const handleClick = (cb) => {
  if (typeof cb === 'function') {
    return cb(false);
  }
  return;
};

const DecideRoutes = ({ setOpen }) => {
  const {
    user: { role, status },
    logout,
  } = useAuth();
  const navigate = useNavigate();

  const isApprovedUser = status === userStatuses.ACTIVE;

  const handleLogout = async () => {
    handleClick(setOpen);
    logout();

    navigate(ADMIN.LOGIN);
  };

  switch (role) {
    case R.ADMIN:
      return (
        <>
          <S.Link to={ADMIN.DASHBOARD} onClick={() => handleClick(setOpen)}>
            <T.P color="white" weight="bold">
              {t.english['HOME']}
            </T.P>
          </S.Link>

          {isApprovedUser && (
            <>
              <S.Link
                to={SUPER_ADMIN.ADD_UPDATE_CONTENT}
                onClick={() => handleClick(setOpen)}
              >
                <T.P color="white" weight="bold">
                  {t.english['ADD_UPDATE_CONTENT']}
                </T.P>
              </S.Link>

              {/*  <S.Link
                to={SUPER_ADMIN.CUSTOMISE}
                onClick={() => handleClick(setOpen)}
              >
                <T.P color="white" weight="bold">
                  {t.english['CUSTOMISE_COLORS']}
                </T.P>
              </S.Link> */}
            </>
          )}

          <S.Link
            to={ADMIN.EDIT_ACCOUNT_DETAILS}
            onClick={() => handleClick(setOpen)}
          >
            <T.P color="white" weight="bold">
              {t.english['EDIT_ACCOUNT_DETAILS']}
            </T.P>
          </S.Link>

          <S.Link onClick={handleLogout}>
            <T.P color="white" weight="bold">
              {t.english['LOG_OUT']}
            </T.P>
          </S.Link>
        </>
      );
    case R.SUPER_ADMIN:
      return (
        <>
          <S.Link
            to={SUPER_ADMIN.DASHBOARD}
            onClick={() => handleClick(setOpen)}
          >
            <T.P color="white" weight="bold">
              {t.english['HOME']}
            </T.P>
          </S.Link>
          <S.Link
            to={SUPER_ADMIN.ADD_UPDATE_CONTENT}
            onClick={() => handleClick(setOpen)}
          >
            <T.P color="white" weight="bold">
              {t.english['ADD_UPDATE_CONTENT']}
            </T.P>
          </S.Link>
          {/*  <S.Link
            to={SUPER_ADMIN.CUSTOMISE}
            onClick={() => handleClick(setOpen)}
          >
            <T.P color="white" weight="bold">
              {t.english['CUSTOMISE_COLORS']}
            </T.P>
          </S.Link> */}
          <S.Link to={SUPER_ADMIN.USERS} onClick={() => handleClick(setOpen)}>
            <T.P color="white" weight="bold">
              {t.english['MANAGE_USERS']}
            </T.P>
          </S.Link>
          {/* <S.Link
            to={SUPER_ADMIN.ORGANISATIONS}
            onClick={() => handleClick(setOpen)}
          >
            <T.P color="white" weight="bold">
              {t.english['ORGANISATIONS']}
            </T.P>
          </S.Link>*/}
          <S.Link to={SUPER_ADMIN.CHANGES} onClick={() => handleClick(setOpen)}>
            <T.P color="white" weight="bold">
              {t.english['CHANGES']}
            </T.P>
          </S.Link>
          <S.Link
            to={SUPER_ADMIN.EDIT_ACCOUNT_DETAILS}
            onClick={() => handleClick(setOpen)}
          >
            <T.P color="white" weight="bold">
              {t.english['EDIT_ACCOUNT_DETAILS']}
            </T.P>
          </S.Link>
          {/* BACKLOG ITEM */}
          {/* <S.Link
            to={ADMIN.CUSTOMISE_RESOURCES}
            onClick={() => handleClick(setOpen)}
          >
            <T.P color="white" weight="bold">
              {t.english['CUSTOMISE_RESOURCES']}
            </T.P>
          </S.Link> */}
          <S.Link onClick={handleLogout}>
            <T.P color="white" weight="bold">
              {t.english['LOG_OUT']}
            </T.P>
          </S.Link>
        </>
      );
    default:
      return (
        <>
          <S.Link to={ADMIN.LOGIN} onClick={() => handleClick(setOpen)}>
            <T.P color="white" weight="bold">
              {t.english['LOG_IN']}
            </T.P>
          </S.Link>
        </>
      );
  }
};

const Routes = ({ setOpen }) => {
  return (
    <>
      <DecideRoutes setOpen={setOpen} />
    </>
  );
};

export default Routes;
