import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../helpers';
import { Stages } from '../api-calls';
import { t } from '../helpers';

const storeStagesIntoStorage = (stages) => {
  localStorage.setItem('stages', JSON.stringify(stages));
};

const getStagesFromStorage = () => {
  const stages = JSON.parse(localStorage.getItem('stages'));
  return stages || [];
};

const updateStagesInStorage = (stagesFromLocal, newStages) => {
  const updatedStages = newStages.map((newStage) => {
    const existingStage = stagesFromLocal.find(
      (stage) => newStage.id === stage.id
    );

    if (existingStage) {
      return {
        ...newStage,
        isCompleted: existingStage.isCompleted,
        isInProgress: existingStage.isInProgress,
        successShown: existingStage.successShown,
      };
    }
    return newStage;
  });
  storeStagesIntoStorage(updatedStages);
  return updatedStages;
};

const StagesContext = createContext({});

const StagesProvider = ({ children, ...props }) => {
  const { i18n } = useTranslation();
  const { lng } = useLanguage();
  const [stages, setStages] = useState(getStagesFromStorage);

  const [loadingStages, setLoadingStages] = useState(false);
  const [stagesError, setStagesError] = useState('');
  const location = useLocation();

  const adminPages = location?.pathname?.includes('/admin/');

  const fetchData = useCallback(
    async (mounted) => {
      setLoadingStages(true);
      const { data: newStages, error } = await Stages.getStages({
        lng: adminPages ? 'en' : lng,
      });
      if (mounted) {
        let updatedStages = [];
        if (error) {
          setStagesError(error.message);
          message.error(t(`generalError`, lng), 2);
          updatedStages = getStagesFromStorage();
        } else {
          const stagesFromLocalStorage = getStagesFromStorage();
          updatedStages = updateStagesInStorage(
            stagesFromLocalStorage,
            newStages
          );
        }
        storeStagesIntoStorage(updatedStages);
        setStages(updatedStages);
        setLoadingStages(false);
      }
    },
    [lng, adminPages]
  );

  const markAsCompleted = useCallback(
    (stageId) => {
      const _stages = stages.map((stage) => {
        if (stage.id === stageId) {
          return {
            ...stage,
            isCompleted: true,
          };
        }
        return stage;
      });

      setStages(_stages);
      storeStagesIntoStorage(_stages);
    },
    [stages]
  );

  const markAsInProgress = useCallback(
    (stageId) => {
      const _stages = stages.map((stage) => {
        if (stage.id === stageId) {
          return {
            ...stage,
            isInProgress: true,
          };
        }
        return stage;
      });

      setStages(_stages);
      storeStagesIntoStorage(_stages);
    },
    [stages]
  );

  const markSuccessAsShown = useCallback(
    (stageId) => {
      const _stages = stages.map((stage) => {
        if (stage.id === stageId) {
          return {
            ...stage,
            successShown: true,
            isCompleted: true,
          };
        }
        return stage;
      });

      setStages(_stages);
      storeStagesIntoStorage(_stages);
    },
    [stages]
  );

  useEffect(() => {
    let mounted = true;

    fetchData(mounted);

    return () => {
      mounted = false;
    };
  }, [fetchData]);

  useEffect(() => {
    i18n.addResourceBundle(lng, 'stages', {
      stages: stages,
    });
  }, [stages, i18n, lng]);

  const value = {
    stages,
    stagesError,
    loadingStages,
    markAsCompleted,
    markAsInProgress,
    markSuccessAsShown,
  };
  return (
    <StagesContext.Provider value={value} {...props}>
      {children}
    </StagesContext.Provider>
  );
};

export const useStages = () => {
  const value = useContext(StagesContext);
  return value;
};

export default StagesProvider;
