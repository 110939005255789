const common = {
  buttons: {
    viewSteps: 'View steps',
    checkHere: 'Check here',
    markAsComplete: 'Mark as complete',
    accessibility: 'Accessibility',
    decreaseTextSize: '- Decrease text size',
    increaseTextSize: '+ Increase text size',
    stuckCallUs: 'Stuck? Call us for advice',
    seeMore: 'See more',
    seeLess: 'See less',
    addColourOverlay: 'Add colour overlay',
    giveUsACall: 'Give us a call',
    viewNextStage: 'View next stage',
    returnHome: 'Return home',
  },
  generalSentence: {
    ThisCanIncludeThingsLike: 'This can include things like:',
  },
  words: {
    and: 'and',
    completed: 'Completed',
    inProgress: 'In progress',
    todo: 'To do',
    todoClickToBegin: 'Click to start this stage',
    phone: 'Phone',
    email: 'Email',
    post: 'Post',
  },
  heading: {
    thingsYouWillNeed: {
      title: 'Things you will need',
    },
    thingsThatMightBeUseful: { title: 'Things that might be useful' },
    whatHappensInThisStage: { title: 'What happens in this stage?' },
    whatWillHappenNext: { title: 'What will happen next?' },
    resources: { title: 'Resources' },
    tip: 'Tip!',
    questionA: 'a',
    questionB: 'b',
    questionC: 'c',
  },
  stages: {
    1: 'stage 1',
    2: 'stage 2',
    3: 'stage 3',
    4: 'stage 4',
    5: 'stage 5',
  },
  section: {
    changeLanguage: {
      title: 'Change language',
      placeholder: 'Search',
    },
    afterClaimContent: {
      title: {
        completed: 'You’re all done!',
        notCompleted: `What should I do once I am granted Universal Credit?`,
      },
      text: {
        completed: `Got your Universal Credit? Great news! Check out these steps on what to do next:`,
        notCompleted: `Once you’ve completed your claim there are few additional steps you can take. Open this when you’ve completed the above steps`,
      },
    },
    helpMe: {
      title: 'Help me',
      subtitle: 'Contact Kidney Care UK',
      description1:
        'Kidney Care UK is the UK’s leading kidney patient support charity. We offer practical, emotional and financial help to ensure no one faces kidney disease alone.',
      description2:
        'Every day we help to improve the quality of life of people living with kidney disease, and we’re here for you.',
      description3:
        'If you’d like to talk to one of our team, get in touch with us, and we will talk through your application with you. Our reception team will take your details and pass them on to one of our advisors, who will then get back to you within 10 working days.',
      available: 'Available 9am to 5pm on weekdays, excluding public holidays.',
      phone: '01420 541 424',
      email: 'info@kidneycareuk.org',
      addressLine1: 'Kidney Care UK,',
      addressLine2: `3 The Windmills, St Mary's Close`,
      addressLine3: 'Alton GU34 1EF',
    },
    accessibility: {
      title: 'Accessibility',
      description1:
        'Accessibility on this website is guided by government standards and the Web Content Accessibility Guidelines WCAG are widely accepted as the international standard for accessibility on the web.',
      description2:
        "Whilst we aim to make this website accessible to all users and achieve a conformance level 'AAA' we continually work with stakeholders to ensure that conformance level 'A' is adhered to as a minimum.",
      tip1: 'Tip! If you experience any accessibility issue on this site or have any comment, please',

      tip2: 'Tip! There are many accessibility features on devices, which can be found on links such as',
      textSizetip: 'Tip! Click increase text size by 25% (e.g. 16px to 20px)',
      contactUs: 'contact us',
      adjustTextSize: 'Adjust Text Size',
      appleAccessibility: 'Apple accessability features',
      androidAccessibility: 'Android accessibility',
      internetExplorerTitle: 'Internet Explorer',
      internetExplorerDescription:
        'Go to “View” on the menu bar - Select text size / zoom',
      firefoxTitle: 'Firefox',
      firefoxDescription:
        'Go to “View” on the menu bar - Select text size / zoom. Alternatively hold down the “Ctrl” button on your keyboard and press the plus (+) key to increase text size. To reduce the latter hold down the “Ctrl” button and press the minus (-) key. Please note that the above settings may differ depending on the browser version.',
      textToSpeechTitle: 'Text To Speech',
      textToSpeechDescription:
        'Many computers and mobile devices today have built in text-to-speech software. Here are guides for each of the major browsers and devices:',
      chromeTitle: 'Chrome',
      googleSpeak: 'Click here to download Google Speak',
      addSpeakButton: 'and select the Add to Chrome button',
      windowsEdgeTitle: 'Windows Edge',
      windowsEdgeDescription:
        'Open the Edge browser and then click on Read Aloud Option or on your keyboard press Ctrl + Shift + U',
      androidApps: 'Android Apps',
      downloadReadAloud: 'Click here to download Read Aloud',
      forAndroid: 'for Android phones or tablets',
      appleTitle: 'Apple',
      downloadVoiceAloud:
        'Click here to download Voice Aloud Reader for Apple devices.',
      searchVoiceAloudReader:
        'Choose your device when you download. Or go to your Apple App store and search Voice Aloud Reader',
      changeFont: 'Change font',
    },
    homePage: {
      isThisHelperForMeTitle: 'Is this helper for me?',
      getStartedTitle: 'Let’s get started...',
      feelingStuckTitle: 'Feeling stuck?',
      mentalHealthTipsText: 'Or for mental health tips,',
      mentalHealthTipsLinkText: 'visit our emotional resilience advice.',
      widthAdditionalSupportFrom: 'With additional support from',
    },
    colors: [
      {
        label: 'Blue',
      },
      {
        label: 'Green',
      },
      {
        label: 'Yellow',
      },
      {
        label: 'Red',
      },
    ],
  },
};

export default common;
