const Rhombus = ({ width, height, color, ...props }) => (
  <svg
    width={width || '371'}
    height={height || '238'}
    viewBox="0 0 371 238"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    {...props}
  >
    <path
      d="M0 .094h356.836c9.18 0 15.876 8.687 13.538 17.565l-55.042 209a14 14 0 0 1-13.538 10.435H0v-237Z"
      fill={color || '#156FB3'}
    />
  </svg>
);

export default Rhombus;
