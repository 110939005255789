import { common } from '../../constants';
import { useTranslation } from 'react-i18next';
import * as S from './style';
import { Icon, Typography as T, Grid, Cards } from '../../components';

const { Row, Col } = Grid;
const { Checklist } = Cards;

const QuestionsSection = ({
  question,
  step,
  stepId,
  checkItem,
  checkUncheckItem,
}) => {
  const { t } = useTranslation();
  const { stepOrder } = step;
  const questionNumber = 'Q' + (stepOrder - 1);
  const key = `question${question}`;

  return (
    step[key]?.length > 0 && (
      <Row mt="6" mtM="6">
        <Col w={[4, 12, 12]}>
          <S.SectionHeader mb="5">
            <Icon
              icon={'checklist2'}
              width={24}
              height={24}
              color={'primaryMain'}
              mr="2"
            />
            <T.P size="xl" weight="bold" color="neutralMain">
              {questionNumber + t(`common.heading.${key}`, common.heading[key])}
            </T.P>
          </S.SectionHeader>
          <Row inner>
            {step[key].map((item, index) => (
              <Col w={[4, 12, 6]} key={index} isFirst={index === 0}>
                <Checklist
                  completed={checkItem(index, key)}
                  handleChange={() => checkUncheckItem(stepId, index, key)}
                  title={item.title}
                  description={item.description}
                  thisCanInclude={item.thisCanInclude}
                  tips={item.tips}
                  mb="4"
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    )
  );
};

const StepQuestion = ({ step, checkUncheckItem, checkItem }) => {
  const { id: stepId } = step;

  return (
    <>
      <QuestionsSection
        question="A"
        step={step}
        stepId={stepId}
        checkItem={checkItem}
        checkUncheckItem={checkUncheckItem}
      />
      <QuestionsSection
        question="B"
        step={step}
        stepId={stepId}
        checkItem={checkItem}
        checkUncheckItem={checkUncheckItem}
      />
      <QuestionsSection
        question="C"
        step={step}
        stepId={stepId}
        checkItem={checkItem}
        checkUncheckItem={checkUncheckItem}
      />
    </>
  );
};

export default StepQuestion;
