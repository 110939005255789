import { common } from '../../constants';
import { useTranslation } from 'react-i18next';
import * as S from './style';
import { Icon, Typography as T, Grid, Cards } from '../../components';

const { Row, Col } = Grid;
const { Checklist } = Cards;

const Section = ({
  section,
  sectionName,
  stepId,
  checkUncheckItem,
  checkItem,
  title,
}) => {
  return (
    section?.length > 0 && (
      <Row mt="6" mtM="6">
        <Col w={[4, 12, 12]}>
          <S.SectionHeader mb="5">
            <Icon
              icon={'checklist2'}
              width={24}
              height={24}
              color="primaryMain"
              mr="2"
            />
            <T.P size="xl" weight="bold" color="neutralMain">
              {title}
            </T.P>
          </S.SectionHeader>
          <Row inner>
            {section.map((item, index) => (
              <Col w={[4, 12, 6]} key={item.title} isFirst={index === 0}>
                <Checklist
                  completed={checkItem(index, sectionName)}
                  handleChange={() =>
                    checkUncheckItem(stepId, index, sectionName)
                  }
                  title={item.title}
                  description={item.description}
                  thisCanInclude={item.thisCanInclude}
                  tips={item.tips}
                  mb="4"
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    )
  );
};

const StepDefault = ({ step, checkUncheckItem, checkItem }) => {
  const { t } = useTranslation();

  return (
    <>
      <Section
        section={step.thingsYouWillNeed}
        sectionName="thingsYouWillNeed"
        stepId={step.id}
        checkUncheckItem={checkUncheckItem}
        checkItem={checkItem}
        title={t(
          'common.heading.thingsYouWillNeed.title',
          common.heading.thingsYouWillNeed.title
        )}
      />
      <Section
        section={step.thingsThatMightBeUseful}
        sectionName="thingsThatMightBeUseful"
        stepId={step.id}
        checkUncheckItem={checkUncheckItem}
        checkItem={checkItem}
        title={t(
          'common.heading.thingsThatMightBeUseful.title',
          common.heading.thingsThatMightBeUseful.title
        )}
      />
      <Section
        section={step.whatHappensInThisStage}
        sectionName="whatHappensInThisStage"
        stepId={step.id}
        checkUncheckItem={checkUncheckItem}
        checkItem={checkItem}
        title={t(
          'common.heading.whatHappensInThisStage.title',
          common.heading.whatHappensInThisStage.title
        )}
      />
      <Section
        section={step.whatWillHappenNext}
        sectionName="whatWillHappenNext"
        stepId={step.id}
        checkUncheckItem={checkUncheckItem}
        checkItem={checkItem}
        title={t(
          'common.heading.whatWillHappenNext.title',
          common.heading.whatWillHappenNext.title
        )}
      />
    </>
  );
};

export default StepDefault;
