const TL = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.25"
      y="3.67999"
      width="23.5"
      height="16.6429"
      rx="1.75"
      fill="white"
      stroke="#F5F5F5"
      strokeWidth="0.5"
    />
    <mask
      id="mask0_54_2540"
      maskUnits="userSpaceOnUse"
      x="0"
      y="3"
      width="24"
      height="18"
    >
      <rect
        x="0.25"
        y="3.67999"
        width="23.5"
        height="16.6429"
        rx="1.75"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
    </mask>
    <g mask="url(#mask0_54_2540)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20.5728H24V11.43H10.6666L11.4285 12.0014L0 20.5728Z"
        fill="#DD1C34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 11.43H10.6667L0 3.42999H24V11.43Z"
        fill="#0D4BC3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.71427 6.85859C2.02986 6.85859 2.28569 6.60275 2.28569 6.28716C2.28569 5.97157 2.02986 5.71573 1.71427 5.71573C1.39867 5.71573 1.14284 5.97157 1.14284 6.28716C1.14284 6.60275 1.39867 6.85859 1.71427 6.85859ZM8.57141 12.5729C8.887 12.5729 9.14284 12.3171 9.14284 12.0015C9.14284 11.6859 8.887 11.43 8.57141 11.43C8.25582 11.43 7.99998 11.6859 7.99998 12.0015C7.99998 12.3171 8.25582 12.5729 8.57141 12.5729ZM2.28569 17.7157C2.28569 18.0313 2.02986 18.2871 1.71427 18.2871C1.39867 18.2871 1.14284 18.0313 1.14284 17.7157C1.14284 17.4001 1.39867 17.1443 1.71427 17.1443C2.02986 17.1443 2.28569 17.4001 2.28569 17.7157ZM3.88462 13.1386L2.9066 14.6411L3.27751 12.887C3.21782 12.8383 3.16316 12.7836 3.11441 12.7239L1.36032 13.0948L2.86287 12.1168C2.85907 12.0789 2.85712 12.0404 2.85712 12.0014C2.85712 11.9625 2.85907 11.924 2.86287 11.8861L1.36032 10.9081L3.11441 11.279C3.16316 11.2193 3.21782 11.1646 3.27751 11.1159L2.9066 9.36179L3.88462 10.8643C3.92255 10.8605 3.96104 10.8586 3.99998 10.8586C4.03892 10.8586 4.07741 10.8605 4.11534 10.8643L5.09336 9.36179L4.72245 11.1159C4.78214 11.1646 4.8368 11.2193 4.88555 11.279L6.63964 10.9081L5.13709 11.8861C5.14089 11.924 5.14284 11.9625 5.14284 12.0014C5.14284 12.0404 5.14089 12.0789 5.13709 12.1168L6.63964 13.0948L4.88556 12.7239C4.8368 12.7836 4.78214 12.8383 4.72245 12.887L5.09336 14.6411L4.11534 13.1386C4.07741 13.1424 4.03892 13.1443 3.99998 13.1443C3.96104 13.1443 3.92255 13.1424 3.88462 13.1386Z"
        fill="#FDD64D"
      />
    </g>
  </svg>
);

export default TL;
