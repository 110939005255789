import {
  Cards as C,
  Grid,
  Typography as T,
  TextWithIcon,
} from '../../../components';
import { Dropdown } from '../../../components/Inputs';
import { navRoutes as R } from '../../../constants';

const { Col, Row } = Grid;

const EditContent = () => {
  // TODO: Add logic here later
  const countryOptions = [
    { label: 'England, Northern Ireland & Wales', value: 'VALUE_1' },
    { label: 'Scotland', value: 'VALUE_2' },
    { label: 'Palestine', value: 'VALUE_3' },
  ];

  return (
    <Row style={{ maxWidth: '700px' }}>
      <Row jc="space-between" mb="7">
        <T.H1 mtM="5" style={{ width: '100%' }}>
          Edit content
        </T.H1>
      </Row>
      <Row jc="space-between" mb="7">
        <Dropdown
          label="Select country"
          options={countryOptions}
          handleChange={() => {}}
        />
      </Row>
      <Row>
        <Col w={[4, 12, 6]}>
          <C.Tips
            tips={[
              <T.P weight="bold" color="secondaryMain">
                Remember! This will change the main content of the tool. Please
                double check any new information and always keep the content
                style supportive.
              </T.P>,
            ]}
            startingColor={1}
          />
        </Col>
        <Col w={[4, 12, 6]} style={{ paddingLeft: '30px' }}>
          <Row>
            <Col w={[12, 12, 12]}>
              <TextWithIcon
                to={R.SUPER_ADMIN.EDIT_LANDING_PAGE}
                text="Edit landing page content"
                m={{ mtT: 7 }}
                iconProps={{
                  icon: 'forwardArrow',
                  color: 'primaryMain',
                }}
              />
            </Col>
            <Col w={[12, 12, 12]}>
              <TextWithIcon
                to={R.SUPER_ADMIN.MANAGE_STAGES}
                text="Edit stage content"
                m={{ mt: 4 }}
                iconProps={{
                  icon: 'forwardArrow',
                  color: 'primaryMain',
                }}
              />
            </Col>
            <Col w={[12, 12, 12]}>
              <TextWithIcon
                to={R.SUPER_ADMIN.MANAGE_STEPS}
                text="Edit step content"
                m={{ mt: 4 }}
                iconProps={{
                  icon: 'forwardArrow',
                  color: 'primaryMain',
                }}
              />
            </Col>
            {/*  <Col w={[12, 12, 12]}>
              <TextWithIcon
                to={R.SUPER_ADMIN.MANAGE_CONTACT_DETAILS}
                text="Edit contact details and links"
                m={{ mt: 4 }}
                iconProps={{
                  icon: 'forwardArrow',
                  color: 'primaryMain',
                }}
              />
            </Col> */}
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

export default EditContent;
