import { validate as _validate, createSchema, fields } from '..';

const { textMax300Required, textMax300Optional, textMax900Optional } = fields;

const schema = createSchema({
  headline: textMax300Required,
  subtitle: textMax300Optional,
  instructions: textMax900Optional,
  isThisHelperForMe: textMax900Optional,
  getStarted: textMax300Optional,
});

const validate = (data) => _validate(schema, data);

export default validate;
