const CircleTick = ({ width, height, color, ...props }) => (
  <svg
    width={width || '21'}
    height={height || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.5" width="20" height="20" rx="10" fill={color || '#004F59'} />
    <line
      x1="9.50018"
      y1="12.8784"
      x2="15.3787"
      y2="6.99997"
      stroke="#F0F4F5"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="1.5"
      y1="-1.5"
      x2="3.99946"
      y2="-1.5"
      transform="matrix(-0.600589 -0.799558 0.793585 -0.60846 10.8028 13.7322)"
      stroke="#F0F4F5"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export default CircleTick;
