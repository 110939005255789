import { useState, useCallback } from 'react';
import { Users } from '../../../api-calls';
import { Grid, Inputs, Modal, Typography as T } from '../../../components';
import * as S from './style';

import { userStatuses } from '../../../constants/data-types';
import userRoles from '../../../constants/roles';

const { Col } = Grid;
const { Dropdown } = Inputs;

const rolesOptions = [
  { label: 'Admin', value: 'ADMIN' },
  { label: 'Super Admin', value: 'SUPER_ADMIN' },
];

const labels = {
  [userRoles.ADMIN]: 'Admin',
  [userRoles.SUPER_ADMIN]: 'Super Admin',
  [userStatuses.ACTIVE]: 'Active',
  [userStatuses.AWAITING_APPROVAL]: 'Awaiting Approval',
  APPROVED: 'Approved',
  [userStatuses.REJECTED]: 'Rejected',
};

const actions = {
  APPROVE_USER: 'APPROVE_USER',
  REJECT_USER: 'REJECT_USER',
  REMOVE_ACCOUNT: 'REMOVE_ACCOUNT',
};

const statusApprovalOptions = {
  [userStatuses.AWAITING_APPROVAL]: [
    { label: 'Approve', value: actions.APPROVE_USER },
    { label: 'Reject', value: actions.REJECT_USER },
    { label: 'Remove account', value: actions.REMOVE_ACCOUNT },
  ],
  [userStatuses.REJECTED]: [
    { label: 'Approve', value: actions.APPROVE_USER },
    { label: 'Remove account', value: actions.REMOVE_ACCOUNT },
  ],
  [userStatuses.ACTIVE]: [
    { label: 'Remove account', value: actions.REMOVE_ACCOUNT },
  ],
};

const UserRow = ({
  name,
  email,
  role,
  status,
  setUsers,
  setError,
  id,
  loggedInUser,
}) => {
  const [originalRole, setOriginalRole] = useState(role);
  const [selectedRole, setSelectedRole] = useState(role);

  const [confirmUpdateRole, setConfirmUpdateRole] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmApproveUser, setConfirmApproveUser] = useState(false);
  const [confirmRejectUser, setConfirmRejectUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleRoleUpdate = useCallback(async () => {
    setLoading(true);
    const { data, error } = await Users.updateUser({
      role: selectedRole,
      id,
    });
    setOriginalRole(selectedRole);
    setLoading(false);
    if (error) {
      setError(error.message);
    } else {
      setUsers((old) =>
        old.map((user) =>
          user.id === data.id ? { ...user, role: selectedRole } : user
        )
      );
      setError('');
      setSuccessMessage('User role is updated successfully!');
      setIsSuccessModalVisible(true);
    }
  }, [id, selectedRole, setError, setUsers]);

  const onCancelUpdateRole = useCallback(() => {
    setSelectedRole(originalRole);
  }, [originalRole]);

  const handleDelete = useCallback(async () => {
    setLoading(true);
    const { error } = await Users.deleteUser({
      id,
    });
    setLoading(false);
    if (error) {
      setError(error.message);
    } else {
      setUsers((old) => old.filter((user) => user.id !== id));
      setError('');
      setSuccessMessage('User is deleted successfully!');
      setIsSuccessModalVisible(true);
    }
  }, [id, setError, setUsers]);

  const handleStatusUpdate = useCallback(
    async (status) => {
      setLoading(true);
      const { data, error } = await Users.updateUser({
        status,
        id,
      });
      setLoading(false);
      if (error) {
        setError(error.message);
      } else {
        setUsers((old) =>
          old.map((user) => (user.id === data.id ? { ...user, status } : user))
        );
        if (status === userStatuses.ACTIVE) {
          setSuccessMessage(
            'This user can now manage content on the platform. Please let them know.'
          );
        } else {
          setSuccessMessage(
            'This user can not manage content on the platform. You can approve it later.'
          );
        }
        setIsSuccessModalVisible(true);
        setError('');
      }
    },
    [id, setError, setUsers]
  );

  const handleUserAction = useCallback((action) => {
    switch (action) {
      case actions.REMOVE_ACCOUNT:
        setConfirmDelete(true);
        break;
      case actions.APPROVE_USER:
        setConfirmApproveUser(true);
        break;
      case actions.REJECT_USER:
        setConfirmRejectUser(true);
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
      <Col w={[4, 12, 2]} mbT={2}>
        <T.P color="neutralMain" weight="bold">
          {name}
        </T.P>
      </Col>
      <Col w={[4, 12, 3]} mbT={2}>
        <T.P color="neutralMain" weight="bold">
          {email} {email === loggedInUser?.email && '(You)'}
        </T.P>
      </Col>
      <Col w={[4, 12, 2]} mbT={2}>
        <Dropdown
          options={rolesOptions}
          selected={selectedRole}
          handleChange={(value) => {
            setSelectedRole(value);
            if (value !== selectedRole) {
              setConfirmUpdateRole(true);
            }
          }}
          disabled={email === loggedInUser?.email || loading}
        />
      </Col>
      <Col w={[4, 12, 2]} mbT={2}>
        <T.P color="neutralMain" weight="bold">
          {labels[status]}
        </T.P>
      </Col>
      <Col w={[4, 12, 3]} mbT={2}>
        <Dropdown
          options={statusApprovalOptions[status]}
          handleChange={handleUserAction}
          disabled={email === loggedInUser?.email || loading}
        />
      </Col>
      <S.Divider />
      <Modal
        type="updateSuccess"
        title="Are you sure?"
        description={
          selectedRole === userRoles.SUPER_ADMIN
            ? 'This will give the user access to edit any content on the tool and manage the access level of other administrators.'
            : 'This will remove the user’s access to edit any content on the tool and manage the access level of other administrators.'
        }
        visible={confirmUpdateRole}
        setIsModalVisible={setConfirmUpdateRole}
        onCancel={onCancelUpdateRole}
        parentFunc={handleRoleUpdate}
      />
      <Modal
        type="updateSuccess"
        title="Are you sure?"
        description="Are you sure you want to delete this user?"
        visible={confirmDelete}
        setIsModalVisible={setConfirmDelete}
        parentFunc={handleDelete}
      />
      <Modal
        type="updateSuccess"
        title="Are you sure?"
        description="This will give the user access to edit any content on the tool."
        visible={confirmApproveUser}
        setIsModalVisible={setConfirmApproveUser}
        parentFunc={() => {
          handleStatusUpdate(userStatuses.ACTIVE);
        }}
      />
      <Modal
        type="updateSuccess"
        title="Are you sure?"
        description="This will prevent the user from having access to edit any content on the tool."
        visible={confirmRejectUser}
        setIsModalVisible={setConfirmRejectUser}
        parentFunc={() => {
          handleStatusUpdate(userStatuses.REJECTED);
        }}
      />
      <Modal
        type="updateSuccess"
        title="Success"
        description={successMessage}
        visible={isSuccessModalVisible}
        setIsModalVisible={setIsSuccessModalVisible}
        btnText="Close"
      />
    </>
  );
};

export default UserRow;
