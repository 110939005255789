import axios from 'axios';
import handleError from './format-error';

const STAGES_BASE = '/stages';

const getStageById = async ({ id, lng, forPublic, options = {} } = {}) => {
  try {
    const { data } = await axios.get(`${STAGES_BASE}/${id}`, {
      params: { lng, forPublic },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getStages = async ({ options, lng }) => {
  try {
    const { data } = await axios.get(`${STAGES_BASE}`, { params: { lng } });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateStage = async ({ id, options, payload }) => {
  try {
    const { data } = await axios.put(`${STAGES_BASE}/${id}`, payload);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export { getStageById, getStages, updateStage };
