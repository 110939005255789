const UZ = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.25"
      y="3.67999"
      width="23.5"
      height="16.6429"
      rx="1.75"
      fill="white"
      stroke="#F5F5F5"
      strokeWidth="0.5"
    />
    <mask
      id="mask0_54_2308"
      maskUnits="userSpaceOnUse"
      x="0"
      y="3"
      width="24"
      height="18"
    >
      <rect
        x="0.25"
        y="3.67999"
        width="23.5"
        height="16.6429"
        rx="1.75"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
    </mask>
    <g mask="url(#mask0_54_2308)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.14428H24V3.42999H0V9.14428Z"
        fill="#04AAC8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20.5728H24V14.8585H0V20.5728Z"
        fill="#23C840"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57141 5.71561C8.887 5.71561 9.14284 5.45977 9.14284 5.14418C9.14284 4.82859 8.887 4.57275 8.57141 4.57275C8.25582 4.57275 7.99998 4.82859 7.99998 5.14418C7.99998 5.45977 8.25582 5.71561 8.57141 5.71561ZM3.99999 8.00136C4.35259 8.00136 4.68033 7.8949 4.95281 7.71238C4.92119 7.71447 4.88929 7.71554 4.85714 7.71554C4.06816 7.71554 3.42857 7.07594 3.42857 6.28697C3.42857 5.49799 4.06816 4.85839 4.85714 4.85839C4.88917 4.85839 4.92096 4.85945 4.95247 4.86152C4.68005 4.67915 4.35244 4.57278 3.99999 4.57278C3.05322 4.57278 2.28571 5.3403 2.28571 6.28707C2.28571 7.23384 3.05322 8.00136 3.99999 8.00136ZM6.85713 7.42996C6.85713 7.74555 6.6013 8.00139 6.28571 8.00139C5.97011 8.00139 5.71428 7.74555 5.71428 7.42996C5.71428 7.11437 5.97011 6.85853 6.28571 6.85853C6.6013 6.85853 6.85713 7.11437 6.85713 7.42996ZM11.4286 5.14418C11.4286 5.45977 11.1727 5.71561 10.8571 5.71561C10.5416 5.71561 10.2857 5.45977 10.2857 5.14418C10.2857 4.82859 10.5416 4.57275 10.8571 4.57275C11.1727 4.57275 11.4286 4.82859 11.4286 5.14418ZM10.8571 8.00139C11.1727 8.00139 11.4286 7.74555 11.4286 7.42996C11.4286 7.11437 11.1727 6.85853 10.8571 6.85853C10.5416 6.85853 10.2857 7.11437 10.2857 7.42996C10.2857 7.74555 10.5416 8.00139 10.8571 8.00139ZM9.14284 7.42996C9.14284 7.74555 8.887 8.00139 8.57141 8.00139C8.25582 8.00139 7.99998 7.74555 7.99998 7.42996C7.99998 7.11437 8.25582 6.85853 8.57141 6.85853C8.887 6.85853 9.14284 7.11437 9.14284 7.42996Z"
        fill="white"
      />
    </g>
  </svg>
);

export default UZ;
