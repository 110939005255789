const Compass = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={width || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.87451 15.1255l4.16499-2.085 2.085-4.16501-4.165 2.08501-2.08499 4.165zm2.66499-3.315a.4996.4996 0 01.105-.165.50055.50055 0 01.2567-.1382.50035.50035 0 01.2901.0285.49994.49994 0 01.3082.4647.49912.49912 0 01-.046.2155.49903.49903 0 01-.1343.1748.49893.49893 0 01-.1964.1001.49959.49959 0 01-.2203.006.50112.50112 0 01-.2015-.0894.5007.5007 0 01-.1435-.1674.5007.5007 0 01-.0575-.2128.50062.50062 0 01.0395-.2168z"
      fill={color || '#FC6244'}
    />
    <path
      d="M19.4999 12a.50001.50001 0 01.5-.5h1c-.126-2.21092-1.0626-4.2978-2.6308-5.8614-1.5683-1.5636-3.6579-2.49413-5.8692-2.61358v.975a.50026.50026 0 01-.1464.35356.50016.50016 0 01-.7072 0 .50026.50026 0 01-.1464-.35356v-.975a9.00008 9.00008 0 00-5.85345 2.62155A9.00008 9.00008 0 003.0249 11.5h.975a.49993.49993 0 01.35356.1465A.4998.4998 0 014.4999 12a.5002.5002 0 01-.14644.3536.50026.50026 0 01-.35356.1464h-.975c.11945 2.2113 1.04998 4.301 2.61357 5.8692C7.20207 19.9374 9.28896 20.8741 11.4999 21v-1a.50001.50001 0 01.8536-.3535.50001.50001 0 01.1464.3535v1a8.99972 8.99972 0 005.8771-2.6228c1.5688-1.5688 2.5029-3.6618 2.6229-5.8772h-1a.50042.50042 0 01-.3536-.1464.50042.50042 0 01-.1464-.3536zm-2.81-3.99998l-2.83 5.63998a.46958.46958 0 01-.22.22l-5.64 2.83a.499.499 0 01-.225.055.50014.50014 0 01-.35-.15.49992.49992 0 01-.15791-.2781A.49995.49995 0 017.3099 16l2.83-5.66a.47002.47002 0 01.22-.22l5.64-2.80998a.50013.50013 0 01.3227-.07466.50028.50028 0 01.2986.14341.50023.50023 0 01.1434.29856.50016.50016 0 01-.0747.32269z"
      fill={color || '#FC6244'}
    />
    <path
      d="M12 .49963A11.50002 11.50002 0 003.86828 20.1314a11.5005 11.5005 0 005.88819 3.1473 11.4997 11.4997 0 006.64443-.6545 11.49957 11.49957 0 005.161-4.2355A11.50047 11.50047 0 0023.5 11.9996c0-3.04996-1.2116-5.97503-3.3683-8.13169C17.9751 1.71124 15.05.49963 12 .49963zm0 21.49997a10.00011 10.00011 0 01-9.23879-6.1731A9.99995 9.99995 0 014.92894 4.92857 9.99992 9.99992 0 0115.8268 2.76084a9.99978 9.99978 0 014.4879 3.68309A10.00003 10.00003 0 0122 11.9996c0 2.6522-1.0536 5.1957-2.9289 7.0711-1.8754 1.8754-4.4189 2.9289-7.0711 2.9289z"
      fill={color || '#FC6244'}
    />
  </svg>
);

export default Compass;
