export default {
  HOME: 'Home',
  ADD_UPDATE_CONTENT: 'Edit Content',
  EDIT_ACCOUNT_DETAILS: 'Account details',
  ORGANISATIONS: 'Organisations',
  CUSTOMISE: 'Customise',
  CUSTOMISE_RESOURCES: 'Customise resources and phone numbers',
  CUSTOMISE_COLORS: 'Customise',
  CHANGES: 'Changes',
  CONTENT_REVIEW: 'Content review',
  LOG_OUT: 'Log out',
  LOG_IN: 'Log in',
  MANAGE_USERS: 'Users',
};
