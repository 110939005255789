import styled from '@emotion/styled';
import setMargin from './../../../helpers/set-margin';
import { Link as RouterLink } from 'react-router-dom';
import { P } from '../../Typography';
import placeHolderImage from '../../assets/stage-placeholder.svg';

export const Wrapper = styled(RouterLink)`
  ${setMargin};
  width: 100%;
  max-width: 100%;
  min-width: 280px;
  min-height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.spacings[2]};
  box-shadow: ${({ theme: { shadows } }) => shadows.elevation1};
  filter: drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1));

  ${({ theme }) => theme.media.mobile} {
    max-width: 100%;
  }
`;

export const CardHead = styled.div`
  width: 100%;
  height: 206px;
  background: ${({ theme: { colors }, bg }) => {
    return bg ? colors[bg] : colors.neutralSurface;
  }};
  background-image: ${({ bgImage }) =>
    bgImage ? `url(${bgImage})` : `url(${placeHolderImage})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: ${({ theme }) => theme.spacings[2]};
  border-top-right-radius: ${({ theme }) => theme.spacings[2]};

  ${({ status, theme }) =>
    status === 'completed' &&
    `
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${theme.colors.tertiaryMain}; 
        border-radius: ${theme.spacings[2]};
        border-bottom-left-radius: ${theme.spacings[3]};
        border-bottom-right-radius: ${theme.spacings[3]};
        opacity: 0.5;
      }
  `}
`;

export const CardFooter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: ${({ theme }) => theme.spacings[4]};
  background: ${({ theme: { colors }, bg }) =>
    bg ? colors[bg] : colors.neutralSurface};
  border-bottom-left-radius: ${({ theme }) => theme.spacings[2]};
  border-bottom-right-radius: ${({ theme }) => theme.spacings[2]};
  gap: ${({ theme }) => theme.spacings[1]};
  z-index: 2;
`;

export const StageP = styled(P)`
  text-transform: uppercase !important;
`;
