import { validate as _validate, createSchema, fields } from '..';

const { firstName, lastName, email, contactLinks, optionalText, urlOptional } =
  fields;

const schema = createSchema({
  firstName,
  lastName,
  email,
  benefitCalculatorLink: urlOptional,
  benefitCalculatorLabel: optionalText,
  contactLinks,
});

const validate = (data) => _validate(schema, data);

export default validate;
