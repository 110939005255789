import { rotator } from '../../../helpers';

const CircleArrow = ({ width, height, color, direction, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '17'}
    height={height || '12'}
    fill="none"
    style={{
      transform: direction && rotator(direction),
    }}
  >
    <g fill-rule="evenodd" clip-path="url(#a)" clip-rule="evenodd">
      <path
        fill="#0570B7"
        d="m.57 1.958.697.829 6.408 7.612.821.975V7.93l-.033.043-5.54-6.58-.697-.829L.57 1.96Zm15.276-.07-.09-.068v.184l.09-.116Z"
      />
      <path
        fill="#F15D22"
        d="m16.467 1.898-.697.828-6.453 7.668-.821.976V7.926l.033.043 5.585-6.637.697-.828 1.656 1.394ZM1.105 1.827l.08-.06v.164l-.08-.104Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M16.5.504v10.992H.5V.504z" />
      </clipPath>
    </defs>
  </svg>
);

export default CircleArrow;
