const Todo = ({ width, height, color, ...props }) => (
  <svg
    width={width || '21'}
    height={height || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#a)">
      <mask
        id="b"
        width="20"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
      >
        <path fill="#fff" d="M20 0H0v20h20V0Z" />
      </mask>
      <g fill="#156FB3" mask="url(#b)">
        <path d="M10 20c5.505 0 10-4.483 10-10S15.505 0 10 0 0 4.483 0 10c.006 5.517 4.482 10 10 10Zm-7.335-5.776s-.012-.024-.012-.042a8.331 8.331 0 0 1-1.119-4.188c0-1.522.403-2.948 1.12-4.188 0-.012 0-.024.011-.042.012-.024.024-.024.043-.054a8.511 8.511 0 0 1 3.014-3.014l.042-.043c.012 0 .024 0 .042-.012a8.53 8.53 0 0 1 4.2-1.119 8.42 8.42 0 0 1 4.188 1.12c.012.011.024.011.042.011.012.012.024.043.054.043a8.574 8.574 0 0 1 3.002 3.014c.012.024.024.024.042.054.012.012.012.024.012.042a8.33 8.33 0 0 1 1.12 4.188 8.33 8.33 0 0 1-1.12 4.188c0 .012 0 .024-.012.042 0 .012-.011.024-.023.042a8.644 8.644 0 0 1-3.015 3.026.132.132 0 0 0-.054.042H14.2a8.34 8.34 0 0 1-8.388 0H5.77l-.042-.042a8.578 8.578 0 0 1-3.027-3.026c0-.018-.018-.03-.036-.042Z" />
        <path d="m4.5 13.754-.685.404a7.69 7.69 0 0 0 2.034 2.033l.403-.698a.501.501 0 0 1 .686-.186.52.52 0 0 1 .186.698l-.403.686a7.434 7.434 0 0 0 2.774.74v-.795a.5.5 0 0 1 .511-.499c.283 0 .5.217.5.5v.794a7.374 7.374 0 0 0 2.773-.74l-.39-.686a.504.504 0 0 1 .174-.698.52.52 0 0 1 .698.186l.39.698a7.78 7.78 0 0 0 2.035-2.033l-.686-.404a.501.501 0 0 1-.187-.686.504.504 0 0 1 .686-.186l.686.403a7.438 7.438 0 0 0 .74-2.774h-.782A.511.511 0 0 1 16.13 10a.5.5 0 0 1 .512-.5h.782a7.161 7.161 0 0 0-.74-2.773l-.686.39a.519.519 0 0 1-.24.067.533.533 0 0 1-.446-.253.506.506 0 0 1 .187-.686l.686-.403a7.691 7.691 0 0 0-2.034-2.033l-.391.698a.52.52 0 0 1-.698.186.505.505 0 0 1-.175-.698l.391-.686a7.377 7.377 0 0 0-2.774-.74v.794c0 .283-.228.5-.499.5a.508.508 0 0 1-.511-.5V2.57a7.468 7.468 0 0 0-2.774.74l.403.686a.524.524 0 0 1-.186.698.498.498 0 0 1-.686-.187l-.403-.697a7.782 7.782 0 0 0-2.034 2.033l.686.403a.501.501 0 0 1-.247.939.51.51 0 0 1-.253-.066l-.686-.391a7.376 7.376 0 0 0-.74 2.774h.783a.5.5 0 0 1 .511.499.511.511 0 0 1-.511.511h-.783c.067.981.325 1.926.74 2.774l.686-.403a.51.51 0 0 1 .686.186.508.508 0 0 1-.186.686ZM9.49 8.291v-2.99A.5.5 0 0 1 10 4.8c.283 0 .5.217.5.5v2.99c.752.217 1.317.89 1.317 1.709 0 .999-.818 1.817-1.817 1.817A1.823 1.823 0 0 1 8.183 10 1.77 1.77 0 0 1 9.49 8.29Z" />
        <path d="M10 10.806a.806.806 0 1 0 0-1.612.806.806 0 0 0 0 1.612Z" />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Todo;
