import {
  Grid as G,
  Typography as T,
  Inputs as I,
  TextWithIcon,
} from '../../../components';

import * as S from './styles';

const newItem = {
  title: '',
  description: [],
  thisCanInclude: [],
  tips: [],
};

const StepSection = ({
  sectionItems,
  sectionTitle,
  sectionKey,
  setState,
  errors,
  optional = true,
  isResourcesLayout = false,
}) => {
  return (
    <>
      <G.Row mt="8">
        <G.Col w={[6, 6, 6]}>
          <T.H5>{sectionTitle}</T.H5>
          {optional && <S.OptionalTag ml="1">(Optional)</S.OptionalTag>}
        </G.Col>
      </G.Row>
      <G.Row>
        {sectionItems.map((item, index) => (
          <G.Col w={[4, 6, 6]} key={index} mt="6">
            <I.InputCMS
              formState={item}
              hideRemove={sectionItems.length === 1}
              handleRemove={() =>
                setState({
                  [sectionKey]: sectionItems.filter(
                    (_element, i) => i !== index
                  ),
                })
              }
              handleChange={(input) =>
                setState({
                  [sectionKey]: sectionItems.map((element, i) =>
                    i === index ? input : element
                  ),
                })
              }
              error={errors && errors[index]}
              isResourcesLayout={isResourcesLayout}
            />
          </G.Col>
        ))}
      </G.Row>
      <G.Row mt="4">
        <G.Col w={[4, 4, 4]}>
          <TextWithIcon
            text="Add another item"
            isButton
            mt="4"
            color="neutralMain"
            handleClick={() =>
              setState({
                [sectionKey]: [...sectionItems, newItem],
              })
            }
            weight="semi"
            disabled={!sectionItems.every((item) => item.title)}
            iconProps={{
              icon: 'add',
              color: 'primaryMain',
              pointer: true,
            }}
          />
        </G.Col>
      </G.Row>
    </>
  );
};

export default StepSection;
