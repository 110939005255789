const EN = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '18'}
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.429993" width="24" height="17.1429" rx="2" fill="white" />
    <mask
      id="mask0_5842_21618"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="18"
    >
      <rect y="0.429993" width="24" height="17.1429" rx="2" fill="white" />
    </mask>
    <g mask="url(#mask0_5842_21618)">
      <rect y="0.429993" width="24" height="17.1429" fill="#0A17A7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-1.09929 -1.21265L9.14287 5.69577V-0.712821H14.8572V5.69573L25.0992 -1.21264L26.3774 0.682303L18.2796 6.14432H24V11.8586H18.2796L26.3774 17.3206L25.0992 19.2155L14.8572 12.3072V18.7157H9.14287V12.3071L-1.09928 19.2156L-2.37744 17.3206L5.72032 11.8586H7.62939e-06V6.14432H5.72034L-2.37744 0.682297L-1.09929 -1.21265Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1356 -1.46751C27.2368 -1.31371 27.1941 -1.10701 27.0403 -1.00583L16.1843 6.13605C16.0305 6.23723 15.8238 6.19457 15.7226 6.04078C15.6214 5.88698 15.6641 5.68028 15.8179 5.5791L26.6739 -1.56278C26.8277 -1.66396 27.0344 -1.6213 27.1356 -1.46751Z"
        fill="#DB1F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1622 18.9165C27.2651 18.7639 27.2247 18.5567 27.0721 18.4538L17.3402 11.8943C17.1876 11.7914 16.9804 11.8317 16.8775 11.9844C16.7746 12.137 16.815 12.3442 16.9676 12.4471L26.6995 19.0066C26.8521 19.1095 27.0593 19.0692 27.1622 18.9165Z"
        fill="#DB1F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-3.56561 -1.18855C-3.46273 -1.34122 -3.25556 -1.38157 -3.1029 -1.27868L7.04826 5.56255C7.20092 5.66544 7.24127 5.8726 7.13839 6.02526C7.0355 6.17792 6.82834 6.21828 6.67568 6.11539L-3.47548 -0.725846C-3.62814 -0.828731 -3.6685 -1.03589 -3.56561 -1.18855Z"
        fill="#DB1F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-3.56699 19.7375C-3.46524 19.8909 -3.25839 19.9328 -3.10497 19.831L8.1472 12.3691C8.30062 12.2674 8.34252 12.0605 8.24077 11.9071C8.13903 11.7537 7.93217 11.7118 7.77875 11.8135L-3.47341 19.2754C-3.62684 19.3772 -3.66873 19.584 -3.56699 19.7375Z"
        fill="#DB1F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10.7157H10.2857V17.5728H13.7143V10.7157H24V7.28714H13.7143V0.429993H10.2857V7.28714H0V10.7157Z"
        fill="#E6273E"
      />
    </g>
  </svg>
);

export default EN;
