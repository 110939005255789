const MK = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.429993" width="24" height="17.1429" rx="2" fill="white" />
    <mask
      id="mask0_4870_6973"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="18"
    >
      <rect y="0.429993" width="24" height="17.1429" rx="2" fill="white" />
    </mask>
    <g mask="url(#mask0_4870_6973)">
      <rect y="3.42999" width="24" height="17.1429" rx="2" fill="white" />
      <mask
        id="mask0_54_3730"
        maskUnits="userSpaceOnUse"
        x="0"
        y="3"
        width="24"
        height="18"
      >
        <rect y="3.42999" width="24" height="17.1429" rx="2" fill="white" />
      </mask>
      <g mask="url(#mask0_54_3730)">
        <rect y="3.42999" width="24" height="17.1429" fill="#E81B26" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 14.8586C13.5779 14.8586 14.8571 13.5794 14.8571 12.0014C14.8571 10.4235 13.5779 9.14429 12 9.14429C10.422 9.14429 9.14282 10.4235 9.14282 12.0014C9.14282 13.5794 10.422 14.8586 12 14.8586Z"
          fill="#FFE94F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.74981 9.66941C8.92236 9.42935 9.12084 9.20919 9.34117 9.01303L1.68141 0.403748L-0.612754 2.95167L8.74981 9.66941ZM9.34114 14.9899C9.12081 14.7937 8.92233 14.5735 8.74979 14.3335L-0.612762 21.0512L1.6814 23.5991L9.34114 14.9899ZM8.03973 12.5672C8.01357 12.3824 8.00003 12.1935 8.00003 12.0015C8.00003 11.8094 8.01357 11.6205 8.03974 11.4357L-1.49012e-05 10.2872V13.7157L8.03973 12.5672ZM12.7846 8.07838L13.7143 3.43002H10.2857L11.2154 8.0784C11.4691 8.02793 11.7315 8.00147 12 8.00147C12.2686 8.00147 12.5309 8.02793 12.7846 8.07838ZM15.9603 11.4357C15.9865 11.6205 16 11.8094 16 12.0015C16 12.1935 15.9865 12.3824 15.9603 12.5672L24 13.7157V10.2872L15.9603 11.4357ZM12.7846 15.9246C12.5309 15.975 12.2686 16.0015 12 16.0015C11.7315 16.0015 11.4691 15.975 11.2154 15.9245L10.2857 20.5729H13.7143L12.7846 15.9246ZM14.6589 14.9899C14.8792 14.7938 15.0777 14.5736 15.2502 14.3335L24.6127 21.0512L22.3186 23.5991L14.6589 14.9899ZM14.6588 9.01298C14.8792 9.20914 15.0777 9.4293 15.2502 9.66936L24.6127 2.95167L22.3186 0.403748L14.6588 9.01298Z"
          fill="#FFE94F"
        />
      </g>
    </g>
  </svg>
);

export default MK;
