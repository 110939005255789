import styled from '@emotion/styled';
import { Link as RLink } from 'react-router-dom';
import * as T from '../../components/Typography';
import setMargin from '../../helpers/set-margin';

export const Container = styled.div`
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const InnerContainer = styled.div`
  ${setMargin}
  max-width: 932px;
  padding-top: ${({ theme }) => theme.spacings[5]};
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: ${({ thin, theme }) => (thin ? theme.spacings[7] : 0)};
  padding-right: ${({ thin, theme }) => (thin ? theme.spacings[7] : 0)};
`;

export const Link = styled(RLink)`
  color: ${({ theme }) => theme.colors.neutralMain};
  display: flex;
`;

export const CloseWrapper = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.spacings[1]};
  left: ${({ theme }) => theme.spacings[5]};
  background: none;
  cursor: pointer;
  border: none;
  z-index: 1000;
  padding: ${({ padding }) => padding};
`;

export const StyledText = styled(T.P)`
  color: ${({ theme: { colors } }) => colors.neutralMain};
  width: 100%;
  text-align: ${({ align }) => `${align}!important` || ''};
`;
