const TextSize = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={width || '24'}
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.2 17.1371L22.959 21.9069L22.9591 21.907M18.2 17.1371L21.9776 22.8883C22.0984 23.0091 22.2622 23.077 22.433 23.077C22.6038 23.077 22.7676 23.0091 22.8883 22.8883C23.0091 22.7676 23.077 22.6038 23.077 22.433C23.077 22.2622 23.0091 22.0984 22.8883 21.9776L22.9591 21.907M18.2 17.1371C19.8475 15.2175 20.6968 12.7378 20.5702 10.2089C20.442 7.64888 19.324 5.23886 17.4521 3.48781C15.5802 1.73676 13.101 0.781756 10.5381 0.824485C7.97524 0.867215 5.52929 1.90433 3.71681 3.71681C1.90433 5.52929 0.867215 7.97524 0.824485 10.5381C0.781756 13.101 1.73676 15.5802 3.48781 17.4521C5.23886 19.324 7.64888 20.442 10.2089 20.5702C12.7378 20.6968 15.2175 19.8475 17.1371 18.2L21.9069 22.959L21.907 22.9591M18.2 17.1371L21.907 22.9591M22.9591 21.907C23.0986 22.0465 23.177 22.2357 23.177 22.433C23.177 22.6303 23.0986 22.8195 22.959 22.959C22.8195 23.0986 22.6303 23.177 22.433 23.177C22.2357 23.177 22.0465 23.0986 21.907 22.9591M22.9591 21.907L21.907 22.9591M2.36639 10.7233C2.3664 9.07047 2.85652 7.45475 3.77479 6.08047C4.69306 4.70617 5.99824 3.63504 7.52527 3.00252C9.05231 2.37001 10.7326 2.20451 12.3537 2.52696C13.9748 2.84942 15.4639 3.64534 16.6326 4.81408C17.8013 5.98282 18.5973 7.47189 18.9197 9.09298C19.2422 10.7141 19.0767 12.3944 18.4442 13.9214C17.8116 15.4484 16.7405 16.7536 15.3662 17.6719C13.992 18.5901 12.3763 19.0803 10.7235 19.0803C8.5079 19.0775 6.38389 18.1961 4.81725 16.6294C3.25063 15.0628 2.36925 12.9388 2.36639 10.7233Z"
      fill="#1A202B"
      stroke="#332C44"
      strokeWidth="0.2"
    />
    <path
      d="M15.7022 15.0435L12.1586 5.70224H9.80451L6.31094 15.0435H8.43964L9.24103 12.7645H12.7346L13.561 15.0435H15.7022ZM9.80451 11.0866L10.9816 7.83094L12.1586 11.0866H9.80451Z"
      fill="#1A202B"
    />
  </svg>
);

export default TextSize;
