import { useEffect, useState } from 'react';
import { Users } from '../../../api-calls';
import { Grid, Typography as T } from '../../../components';
import { useAuth } from '../../../context/auth';
import UserRow from './UserRow';

const { Row, Col } = Grid;

const UsersManagement = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');
  const { user: loggedInUser } = useAuth();

  useEffect(() => {
    const getUsers = async () => {
      const { data, error } = await Users.getAdminUsers();
      if (error) {
        setError(error.message);
      } else {
        setUsers(data);
      }
    };

    getUsers();
  }, []);

  return (
    <>
      <Row>
        <Col w={[4, 12, 12]}>
          <T.H1 mtM="5" style={{ width: '100%' }}>
            Users
          </T.H1>
        </Col>
      </Row>
      <Row mt="8">
        <Col w={[0, 0, 2]}>
          <T.P color="neutralMain" mb="6" weight="bold">
            Name
          </T.P>
        </Col>
        <Col w={[0, 0, 3]}>
          <T.P color="neutralMain" mb="6" weight="bold">
            Email
          </T.P>
        </Col>
        <Col w={[0, 0, 2]}>
          <T.P color="neutralMain" mb="6" weight="bold">
            Role
          </T.P>
        </Col>
        <Col w={[0, 0, 2]}>
          <T.P color="neutralMain" mb="6" weight="bold">
            Status
          </T.P>
        </Col>
        <Col w={[0, 0, 3]}>
          <T.P color="neutralMain" mb="6" weight="bold">
            Action
          </T.P>
        </Col>
      </Row>
      {users &&
        users.map((user) => (
          <Row key={user.id} ai="center" mb={6} mbT={2}>
            <UserRow
              name={`${user.firstName} ${user.lastName}`}
              email={user.email}
              role={user.role}
              status={user.status}
              setUsers={setUsers}
              setError={setError}
              id={user.id}
              loggedInUser={loggedInUser}
              signupDate={user.createdAt}
            />
          </Row>
        ))}
      {error && <T.P color="error">{error}</T.P>}
    </>
  );
};

export default UsersManagement;
