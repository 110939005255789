import StepSection from './StepSection';

const QuestionStepSections = ({
  state,
  setState,
  validationErrs,
  ...props
}) => {
  const { questionA, questionB, questionC } = state;

  const sections = [
    {
      sectionItems: questionA,
      sectionTitle: 'Section 1',
      sectionKey: 'questionA',
      setState,
      errors: validationErrs.questionA,
    },
    {
      sectionItems: questionB,
      sectionTitle: 'Section 2',
      sectionKey: 'questionB',
      setState,
      errors: validationErrs.questionB,
    },
    {
      sectionItems: questionC,
      sectionTitle: 'Section 3',
      sectionKey: 'questionC',
      setState,
      errors: validationErrs.questionC,
    },
  ];

  return (
    <>
      {sections.map((section) => (
        <StepSection
          key={`DEFAULT_SECTION_${section.sectionKey}`}
          {...section}
          {...props}
        />
      ))}
    </>
  );
};

export default QuestionStepSections;
