import { useState } from 'react';
import * as S from './style';
import * as T from '../Typography';
import Icon from '../Icon';
import AdminHelp from './AdminHelp';
import { linkTypes as cType } from '../../constants/data-types';
import { usePublicOrg } from '../../context/public-org';
import { useTranslation } from 'react-i18next';
import { useAccessibility } from '../../context/accessibility';
import { common } from '../../constants';

const formatLink = (type, contact) => {
  switch (type) {
    case cType.EMAIL:
      return { link: `mailto:${contact.email}`, label: contact.email };
    case cType.PHONE:
      return { link: `tel:${contact.phoneNumber}`, label: contact.phoneNumber };
    case cType.WEBCHAT_LINK:
      return { link: contact.link, label: contact.link };
    default:
      return { link: '', label: '' };
  }
};

const HelpButton = ({
  position = {},
  customContacts = [],
  parentState,
  parentFunc,
  uniqueSlug,
  adminHelp,
  ...props
}) => {
  const { t } = useTranslation();
  const { isFontLarge } = useAccessibility();
  const [isOpen, setIsOpen] = useState(false);
  const { publicOrg } = usePublicOrg();
  const handleClose = () => {
    setIsOpen(false);
    if (parentFunc) {
      parentFunc(false);
    }
  };

  if (isOpen || parentState)
    return (
      <S.ModalWrapper>
        <S.Modal adminHelp={adminHelp} isFontLarge={isFontLarge} {...props}>
          <S.Header>
            <T.P weight="medium" color="white">
              {adminHelp
                ? t('common.section.helpMe.title', common.section.helpMe.title)
                : t(
                    'common.section.helpMe.subtitle',
                    common.section.helpMe.subtitle
                  )}
            </T.P>
            <S.CloseButton onClick={handleClose}>
              <Icon icon="close" color="white" pointer />
            </S.CloseButton>
          </S.Header>
          <S.Content>
            {adminHelp ? (
              <AdminHelp />
            ) : (
              <>
                <T.P size="small" color="neutralMain" mb="4">
                  {t(
                    'common.section.helpMe.description1',
                    common.section.helpMe.description1
                  )}
                </T.P>
                <T.P size="small" color="neutralMain" mb="4">
                  {t(
                    'common.section.helpMe.description2',
                    common.section.helpMe.description2
                  )}
                </T.P>
                <T.P size="small" color="neutralMain" mb="4">
                  {t(
                    'common.section.helpMe.description3',
                    common.section.helpMe.description3
                  )}
                </T.P>

                <T.P color="neutralMain" weight="medium" size="small" mb={4}>
                  {t(
                    'common.section.helpMe.available',
                    common.section.helpMe.available
                  )}
                </T.P>

                <S.ContactItem mb={1}>
                  <T.P color="neutralMain" size="small">
                    {t('common.words.phone', common.words.phone)}:
                  </T.P>
                  <T.Link
                    external
                    weight="bold"
                    color="primaryDark"
                    size="small"
                    to={
                      formatLink('PHONE', {
                        phoneNumber: common.section.helpMe.phone,
                      }).link
                    }
                    dir={'ltr'}
                  >
                    {common.section.helpMe.phone}
                  </T.Link>
                </S.ContactItem>

                <S.ContactItem mb={1}>
                  <T.P color="neutralMain" size="small">
                    {t('common.words.email', common.words.email)}:
                  </T.P>
                  <T.Link
                    external
                    weight="bold"
                    color="primaryDark"
                    size="small"
                    to={
                      formatLink('EMAIL', {
                        email: common.section.helpMe.email,
                      }).link
                    }
                    dir={'ltr'}
                  >
                    {common.section.helpMe.email}
                  </T.Link>
                </S.ContactItem>

                <S.ContactItem>
                  <T.P color="neutralMain" size="small">
                    {t('common.words.post', common.words.post)}:
                  </T.P>
                  <T.P color="primaryDark" weight="bold" size="small">
                    {common.section.helpMe.addressLine1}
                    <br />
                    {common.section.helpMe.addressLine2}
                    <br />
                    {common.section.helpMe.addressLine3}
                  </T.P>
                </S.ContactItem>
              </>
            )}
          </S.Content>
        </S.Modal>
      </S.ModalWrapper>
    );

  return (
    <S.Button
      position={position}
      isFontLarge={isFontLarge}
      onClick={() => setIsOpen(true)}
    >
      <T.P weight="bold" color="white">
        {t('common.section.helpMe.title', common.section.helpMe.title)}
      </T.P>
    </S.Button>
  );
};

export default HelpButton;
