import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';
import * as T from '../../components/Typography';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacings[2]};

  ${({ theme }) => theme.media.tablet} {
    justify-content: start;
  }
`;

export const PageHead = styled.div`
  height: 290px;
  width: 900px;
  background: ${({ theme }) => theme.colors.secondaryMain};
  border-radius: ${({ theme: { spacings } }) => spacings[3]};
  position: relative;

  &:after {
    height: 100%;
    position: absolute;
    content: '';
    top: 0;
    right: -50px;
    width: 250px;
    background: inherit;
    transform: skew(-20deg);
    border-top-right-radius: ${({ theme: { spacings } }) => spacings[4]};
    border-bottom-right-radius: ${({ theme: { spacings } }) => spacings[3]};
    z-index: -1;
  }

  ${({ theme }) => theme.media.tablet} {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 82%;
  }

  ${({ theme }) => theme.media.mobile} {
    width: 75%;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { spacings } }) => `${spacings[7]}`};
  gap: ${({ theme }) => theme.spacings[7]};
  ${({ theme }) => theme.media.mobile} {
    gap: ${({ theme }) => theme.spacings[6]};
    padding: ${({ theme: { spacings } }) => `${spacings[6]}`};
  }
`;

export const HeaderText = styled.div`
  width: 80%;
  padding-bottom: ${({ theme }) => theme.spacings[5]};
  border-bottom: 6px solid;
  border-color: ${({ theme: { colors } }) => colors.primaryMain};
  ${({ theme }) => theme.media.tablet} {
    width: 100%;
  }
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;

export const LogoContainer = styled(Link)`
  width: 72px;
  height: 30px;
  margin: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const Section = styled.section`
  ${setMargin}
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${({ mw }) => mw || '100%'};

  .ant-typography {
    padding: ${({ theme: { spacings } }) =>
      `${spacings[5]} ${spacings[6]} 0 ${spacings[7]}`};
  }
`;

export const StyledText = styled(T.P)`
  color: ${({ theme: { colors } }) => colors.neutralMain};
  width: 100%;
  text-align: ${({ align }) => `${align}!important` || ''};
`;

export const StyledTitle = styled(T.H4)`
  color: ${({ theme: { colors } }) => colors.neutralMain};
  width: 100%;
  text-align: ${({ align }) => `${align}!important` || ''};
`;

export const Span = styled.span`
  color: ${({ theme: { colors } }) => colors.neutralMain};
`;

export const Container = styled.div`
  width: 100%;
  max-width: 932px;
  margin-left: auto;
  margin-right: auto;
`;

export const StagesContainer = styled.div`
  padding: ${({ theme: { spacings } }) => `0 ${spacings[6]}`};
`;

export const StagesSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`;

export const StageWrapper = styled.div`
  width: calc(50% - 10px);
  margin-bottom: 20px;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;

export const HorizontalDivider = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : '2px')};
  background-color: ${({ theme, color }) => color || theme.colors.primaryMain};
  margin: ${({ theme }) => `${theme.spacings[5]} auto`};
`;

export const HyperLink = styled.a`
  text-decoration: underline !important;
  color: ${({ theme }) => theme.colors.neutralMain} !important;
`;
