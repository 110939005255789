import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Grid as G,
  Inputs as I,
  Modal,
  Typography as T,
} from '../../../components';

import GeneralTips from './GeneralTips';

import { Stages } from '../../../api-calls';
import { navRoutes } from '../../../constants';
import { stageTypes } from '../../../constants/data-types';
import { useAdminOrg } from '../../../context/admin-org';
import { StageForm as validate } from '../../../validation/schemas';

const initialState = {
  headline: '',
  subtitle: '',
  instructions: '',
  stageType: '',
  videoLink: '',
  httpError: '',
  topTip: '',
  otherTips: [''],
  validationErrs: {},
  loading: false,
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const StageForm = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const saveForPreview = useRef(false);
  const { adminOrg } = useAdminOrg();

  const submitAttempt = useRef(false);
  const [state, setState] = useReducer(reducer, initialState);
  const {
    headline,
    subtitle,
    instructions,
    stageType,
    videoLink,
    validationErrs,
    httpError,
    otherTips,
    topTip,
  } = state;

  const { id: stageId } = useParams();

  const fetchStageData = useCallback(async () => {
    setState({ loading: true });
    const { error, data } = await Stages.getStageById({
      id: stageId,
      lng: 'en',
      forPublic: false,
    });

    setState({ loading: false });
    if (error) {
      return setState({ httpError: error.message });
    }

    setState({
      ...data,
      otherTips: data?.otherTips?.length
        ? data.otherTips
        : initialState.otherTips,
    });
  }, [stageId]);

  const validateForm = useCallback(() => {
    try {
      validate({
        ...state,
        otherTips: otherTips.filter(
          (e, i) => !(i === otherTips.length - 1 && !e)
        ),
      });
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  }, [state, otherTips]);

  const handleEditStage = useCallback(async () => {
    setState({ loading: true });

    const { error } = await Stages.updateStage({
      id: stageId,
      payload: {
        ...state,
        otherTips: otherTips.filter((e, i) => !!e),
      },
    });

    setState({ loading: false });
    if (error) {
      setState({ httpError: error.message });
    } else {
      if (saveForPreview.current) {
        window.open(
          navRoutes.GENERAL.STAGE_ORG.replace(
            ':uniqueSlug',
            adminOrg.uniqueSlug
          ).replace(':id', stageId),
          '_blank'
        );
      } else {
        setIsModalVisible(true);
      }
    }
    saveForPreview.current = false;
  }, [adminOrg, state, stageId, otherTips]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      submitAttempt.current = true;

      const isValid = validateForm();
      if (isValid) {
        handleEditStage();
      }
    },
    [handleEditStage, validateForm]
  );

  useEffect(() => {
    fetchStageData();
  }, [fetchStageData]);

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
  }, [
    headline,
    subtitle,
    instructions,
    stageType,
    videoLink,
    httpError,
    otherTips,
    topTip,
  ]);

  return (
    <G.Row>
      <G.Col w={[4, 12, 12]}>
        <T.H1 mb="6">Edit Stage Content</T.H1>
      </G.Col>
      <G.Col mb="6" w={[4, 8, 8]}>
        <I.BasicInput
          name="headline"
          placeholder="Type headline here..."
          label="Section title"
          error={validationErrs.headline}
          value={headline}
          handleChange={(input) => setState({ headline: input })}
        />
      </G.Col>
      <G.Col mb="6" w={[4, 8, 8]}>
        <I.Dropdown
          label="Application stage"
          options={[
            { label: ' Before application', value: stageTypes.BEFORE_CLAIMING },
            { label: ' Claiming', value: stageTypes.CLAIMING },
            { label: ' After application', value: stageTypes.AFTER_CLAIMING },
          ]}
          selected={stageType}
          handleChange={(selectValue) =>
            setState({
              stageType: selectValue,
            })
          }
          error={validationErrs?.whereDoYouNeedToGo?.type}
        />
      </G.Col>
      <G.Col mb="6" w={[4, 8, 8]}>
        <I.BasicInput
          name="subtitle"
          placeholder="Type subtitle here..."
          label="Subtitle"
          error={validationErrs.subtitle}
          value={subtitle}
          handleChange={(input) => setState({ subtitle: input })}
        />
      </G.Col>
      <G.Col mb="6" w={[4, 8, 8]}>
        <I.Textarea
          name="instructions"
          placeholder="Type instructions here..."
          label="Instructions"
          autoSize={{ minRows: 2, maxRows: 15 }}
          error={validationErrs.instructions}
          value={instructions}
          handleChange={(input) => setState({ instructions: input })}
        />
      </G.Col>
      <G.Col mb="6" w={[4, 8, 8]}>
        <I.BasicInput
          name="videoLink"
          placeholder="Type/paste link here..."
          label="Video Link"
          optional
          error={validationErrs.videoLink}
          value={videoLink}
          handleChange={(input) => setState({ videoLink: input })}
        />
      </G.Col>

      <GeneralTips
        otherTips={otherTips}
        topTip={topTip}
        setState={setState}
        errors={validationErrs}
      />
      <G.Row mt="10">
        {httpError && (
          <G.Col w={[4, 12, 12]}>
            <T.P mb="2" color="error">
              {httpError}
            </T.P>
          </G.Col>
        )}
        {Object.values(validationErrs)?.length ? (
          <G.Col w={[4, 12, 12]}>
            <T.P mb="2" color="error">
              At least one of the input fields has not been filled in or details
              entered incorrectly. Please check the form above for more details.
            </T.P>
          </G.Col>
        ) : null}
        <G.Col w={[4, 6, 4]}>
          <Button
            text="Save"
            handleClick={handleSubmit}
            mb="4"
            mt="4"
            loading={state.loading}
          />
        </G.Col>
        <G.Col w={[4, 6, 4]}>
          <Button
            text="Save and preview"
            handleClick={(e) => {
              saveForPreview.current = true;
              handleSubmit(e);
            }}
            variant="secondary"
            mb="4"
            mt="4"
            loading={state.loading}
          />
        </G.Col>
      </G.Row>
      <Modal
        visible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        parentFunc={() => {}}
        type="updateSuccess"
        title="Updated"
        description="Changes successfully updated."
        btnText="Okay"
      />
    </G.Row>
  );
};

export default StageForm;
