import { message } from 'antd';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Steps } from '../api-calls';
import { t, useLanguage } from '../helpers';

const storeStepsIntoStorage = (steps) => {
  localStorage.setItem('steps', JSON.stringify(steps));
};

const compareCheckListItems = (stepFromLocal = [], step) => {
  const updateChecklist = (existingList, newList) => {
    return newList.map((newItem, i) => {
      const existing = existingList?.[i];

      if (existing) {
        return {
          ...newItem,
          isChecked: existing.isChecked,
        };
      }
      return newItem;
    });
  };

  const updatedThingsYouWillNeed = updateChecklist(
    stepFromLocal.thingsYouWillNeed,
    step.thingsYouWillNeed
  );

  const updatedThingsThatMightBeUseful = updateChecklist(
    stepFromLocal.thingsThatMightBeUseful,
    step.thingsThatMightBeUseful
  );
  const updatedWhatHappensInThisStage = updateChecklist(
    stepFromLocal.whatHappensInThisStage,
    step.whatHappensInThisStage
  );
  const updatedWhatWillHappenNext = updateChecklist(
    stepFromLocal.whatWillHappenNext,
    step.whatWillHappenNext
  );

  const updatedQuestionA = updateChecklist(
    stepFromLocal.questionA,
    step.questionA
  );

  const updatedQuestionB = updateChecklist(
    stepFromLocal.questionB,
    step.questionB
  );

  const updatedQuestionC = updateChecklist(
    stepFromLocal.questionC,
    step.questionC
  );

  return {
    updatedThingsYouWillNeed,
    updatedThingsThatMightBeUseful,
    updatedWhatHappensInThisStage,
    updatedWhatWillHappenNext,
    updatedQuestionA,
    updatedQuestionB,
    updatedQuestionC,
  };
};

// compare two objects and add or remove properties based on the first object
const updateStepsInStorage = (stepsFromLocal, newSteps) => {
  const updatedSteps = newSteps.map((newStep) => {
    const existingStep = stepsFromLocal.find((step) => newStep.id === step.id);

    if (existingStep) {
      const {
        updatedThingsYouWillNeed,
        updatedThingsThatMightBeUseful,
        updatedWhatHappensInThisStage,
        updatedWhatWillHappenNext,
        updatedQuestionA,
        updatedQuestionB,
        updatedQuestionC,
      } = compareCheckListItems(existingStep, newStep);

      return {
        ...newStep,
        thingsYouWillNeed: updatedThingsYouWillNeed,
        thingsThatMightBeUseful: updatedThingsThatMightBeUseful,
        whatHappensInThisStage: updatedWhatHappensInThisStage,
        whatWillHappenNext: updatedWhatWillHappenNext,
        questionA: updatedQuestionA,
        questionB: updatedQuestionB,
        questionC: updatedQuestionC,
        isCompleted: existingStep.isCompleted,
        isInProgress: existingStep.isInProgress,
      };
    }
    return newStep;
  });
  storeStepsIntoStorage(updatedSteps);
  return updatedSteps;
};

const getStepsFromStorage = () => {
  const steps = JSON.parse(localStorage.getItem('steps'));
  return steps || [];
};

const StepsContext = createContext({
  steps: [],
  checkUncheckItem: (stepName, itemKey) => {},
});

const formateStepsObj = (stepsArr) => {
  return stepsArr.reduce((acc, curr) => {
    const { stage } = curr;
    if (!acc[stage]) {
      acc[stage] = [];
    }
    acc[stage].push(curr);
    return acc;
  }, {});
};

const StepsProvider = ({ children, ...props }) => {
  const { i18n } = useTranslation();
  const { lng } = useLanguage();
  const [steps, setSteps] = useState(getStepsFromStorage);
  const [stepsObj, setStepsObj] = useState({
    beforeClaiming: [],
    claiming: [],
    afterClaiming: [],
  });
  const [justCompletedId, setJustCompletedId] = useState('');
  const [loadingSteps, setLoadingSteps] = useState(false);
  const [stepsError, setStepsError] = useState('');
  const location = useLocation();

  const adminPages = location?.pathname?.includes('/admin/');

  const fetchData = useCallback(
    async (mounted, stage) => {
      setLoadingSteps(true);
      const { data: newSteps, error } = await Steps.getStepsContent({
        lng: adminPages ? 'en' : lng,
        stage,
      });
      if (mounted) {
        let updatedSteps = [];
        if (error) {
          setStepsError(error.message);
          message.error(t(`generalError`, lng), 2);
          // To update stepObj state
          updatedSteps = getStepsFromStorage();
        } else {
          const stepsFromLocal = getStepsFromStorage();
          updatedSteps = updateStepsInStorage(stepsFromLocal, newSteps);
          setSteps(updatedSteps);
        }

        const _stepsObj = formateStepsObj(updatedSteps);
        setStepsObj(_stepsObj);
        setLoadingSteps(false);
      }
    },
    [adminPages, lng]
  );

  useEffect(() => {
    const updatedSteps = steps.map((step) => {
      if (step.id === justCompletedId) {
        return { ...step, isCompleted: true };
      }
      return step;
    });
    const _stepsObj = formateStepsObj(updatedSteps);
    i18n.addResourceBundle(lng, 'stepsObj', {
      stepsObj: _stepsObj,
    });
    setStepsObj(_stepsObj);
  }, [justCompletedId, steps, i18n, lng]);

  const checkUncheckItem = (stepId, index, key) => {
    setSteps((prevSteps) => {
      const newSteps = prevSteps.map((step) => {
        if (step.id === stepId) {
          const newStep = { ...step };

          let uncheckedStepsThingsYouWillNeed = step?.thingsYouWillNeed?.length;
          const newCheckListItemsThingsYouWillNeed = step.thingsYouWillNeed.map(
            (item, checklistIndex) => {
              const newItem = { ...item };

              if (checklistIndex === index && key === 'thingsYouWillNeed') {
                newItem.isChecked = !item.isChecked;
              }

              if (newItem.isChecked) {
                uncheckedStepsThingsYouWillNeed--;
              }

              return newItem;
            }
          );

          newStep.thingsYouWillNeed = newCheckListItemsThingsYouWillNeed;

          let uncheckedStepsThingsThatMightBeUseful =
            step?.thingsThatMightBeUseful?.length;
          const newCheckListItemsThingsThatMightBeUseful =
            step.thingsThatMightBeUseful.map((item, checklistIndex) => {
              const newItem = { ...item };

              if (
                checklistIndex === index &&
                key === 'thingsThatMightBeUseful'
              ) {
                newItem.isChecked = !item.isChecked;
              }

              if (newItem.isChecked) {
                uncheckedStepsThingsThatMightBeUseful--;
              }

              return newItem;
            });

          newStep.thingsThatMightBeUseful =
            newCheckListItemsThingsThatMightBeUseful;

          let uncheckedStepsWhatHappensInThisStage =
            step?.whatHappensInThisStage?.length;
          const newCheckListItemsWhatHappensInThisStage =
            step.whatHappensInThisStage.map((item, checklistIndex) => {
              const newItem = { ...item };

              if (
                checklistIndex === index &&
                key === 'whatHappensInThisStage'
              ) {
                newItem.isChecked = !item.isChecked;
              }

              if (newItem.isChecked) {
                uncheckedStepsWhatHappensInThisStage--;
              }

              return newItem;
            });

          newStep.whatHappensInThisStage =
            newCheckListItemsWhatHappensInThisStage;

          let uncheckedStepsWhatWillHappenNext =
            step?.whatWillHappenNext?.length;
          const newCheckListItemswhatWillHappenNext =
            step.whatWillHappenNext.map((item, checklistIndex) => {
              const newItem = { ...item };

              if (checklistIndex === index && key === 'whatWillHappenNext') {
                newItem.isChecked = !item.isChecked;
              }

              if (newItem.isChecked) {
                uncheckedStepsWhatWillHappenNext--;
              }

              return newItem;
            });

          newStep.whatWillHappenNext = newCheckListItemswhatWillHappenNext;

          let uncheckedStepsQuestionA = step?.questionA?.length;
          const newCheckListItemsQuestionA = step.questionA.map(
            (item, checklistIndex) => {
              const newItem = { ...item };

              if (checklistIndex === index && key === 'questionA') {
                newItem.isChecked = !item.isChecked;
              }

              if (newItem.isChecked) {
                uncheckedStepsQuestionA--;
              }

              return newItem;
            }
          );

          newStep.questionA = newCheckListItemsQuestionA;

          let uncheckedStepsQuestionB = step?.questionB?.length;
          const newCheckListItemsQuestionB = step.questionB.map(
            (item, checklistIndex) => {
              const newItem = { ...item };

              if (checklistIndex === index && key === 'questionB') {
                newItem.isChecked = !item.isChecked;
              }

              if (newItem.isChecked) {
                uncheckedStepsQuestionB--;
              }

              return newItem;
            }
          );

          newStep.questionB = newCheckListItemsQuestionB;

          let uncheckedStepsQuestionC = step?.questionC?.length;
          const newCheckListItemsQuestionC = step.questionC.map(
            (item, checklistIndex) => {
              const newItem = { ...item };

              if (checklistIndex === index && key === 'questionC') {
                newItem.isChecked = !item.isChecked;
              }

              if (newItem.isChecked) {
                uncheckedStepsQuestionC--;
              }

              return newItem;
            }
          );

          newStep.questionC = newCheckListItemsQuestionC;

          newStep.isCompleted =
            newStep.isCompleted ||
            (uncheckedStepsThingsYouWillNeed === 0 &&
              uncheckedStepsThingsThatMightBeUseful === 0 &&
              uncheckedStepsWhatHappensInThisStage === 0 &&
              uncheckedStepsWhatWillHappenNext === 0 &&
              uncheckedStepsQuestionA === 0 &&
              uncheckedStepsQuestionB === 0 &&
              uncheckedStepsQuestionC === 0);

          newStep.isInProgress =
            newStep.isInProgress ||
            (!!step?.thingsYouWillNeed?.length &&
              uncheckedStepsThingsYouWillNeed <
                step?.thingsYouWillNeed?.length) ||
            (!!step?.thingsThatMightBeUseful?.length &&
              uncheckedStepsThingsThatMightBeUseful <
                step?.thingsThatMightBeUseful?.length) ||
            (!!step?.whatHappensInThisStage?.length &&
              uncheckedStepsWhatHappensInThisStage <
                step?.whatHappensInThisStage?.length) ||
            (!!step?.whatWillHappenNext?.length &&
              uncheckedStepsWhatWillHappenNext <
                step?.whatWillHappenNext?.length) ||
            (!!step?.questionA?.length &&
              uncheckedStepsQuestionA < step?.questionA?.length) ||
            (!!step?.questionB?.length &&
              uncheckedStepsQuestionB < step?.questionB?.length) ||
            (!!step?.questionC?.length &&
              uncheckedStepsQuestionC < step?.questionC?.length);

          return newStep;
        }
        return { ...step };
      });

      storeStepsIntoStorage(newSteps);
      return newSteps;
    });
  };

  const markAsComplete = (stepId) => {
    setSteps((prevSteps) => {
      let lastCompletedStepStageId;
      const newSteps = prevSteps.map((step) => {
        if (step.id === stepId) {
          const thingsYouWillNeed = step.thingsYouWillNeed.map((item) => ({
            ...item,
            isChecked: true,
          }));

          const thingsThatMightBeUseful = step.thingsThatMightBeUseful.map(
            (item) => ({
              ...item,
              isChecked: true,
            })
          );

          const whatHappensInThisStage = step.whatHappensInThisStage.map(
            (item) => ({
              ...item,
              isChecked: true,
            })
          );

          const whatWillHappenNext = step.whatWillHappenNext.map((item) => ({
            ...item,
            isChecked: true,
          }));

          const questionA = step.questionA.map((item) => ({
            ...item,
            isChecked: true,
          }));

          const questionB = step.questionB.map((item) => ({
            ...item,
            isChecked: true,
          }));

          const questionC = step.questionC.map((item) => ({
            ...item,
            isChecked: true,
          }));

          lastCompletedStepStageId = step.stageId;

          const completedStep = {
            ...step,
            thingsYouWillNeed,
            thingsThatMightBeUseful,
            whatHappensInThisStage,
            whatWillHappenNext,
            questionA,
            questionB,
            questionC,
            isInProgress: false,
            isCompleted: true,
          };

          storeStepsIntoStorage(completedStep);

          return completedStep;
        }

        // Mark next step after the completed one as in progress (Required in #40)
        if (
          step.stageId === lastCompletedStepStageId &&
          !step.isCompleted &&
          !step.isInProgress
        ) {
          // Do this for only 1 next step in the current stage
          lastCompletedStepStageId = null;
          return {
            ...step,
            isInProgress: true,
          };
        }

        return { ...step };
      });

      storeStepsIntoStorage(newSteps);
      setJustCompletedId(stepId);
      return newSteps;
    });
  };

  const markAsInProgress = useCallback(
    (stepId) => {
      const _steps = steps.map((step) => {
        if (step.id === stepId && !step.isInProgress && !step.isCompleted) {
          return {
            ...step,
            isInProgress: true,
          };
        }
        return step;
      });

      setSteps(_steps);
      storeStepsIntoStorage(_steps);
    },
    [steps]
  );

  const value = {
    steps,
    stepsObj,
    checkUncheckItem,
    justCompletedId,
    setJustCompletedId,
    stepsError,
    loadingSteps,
    markAsComplete,
    markAsInProgress,
    fetchSteps: fetchData,
  };
  return (
    <StepsContext.Provider value={value} {...props}>
      {children}
    </StepsContext.Provider>
  );
};

export const useSteps = () => {
  const value = useContext(StepsContext);
  return value;
};

export default StepsProvider;
