import { fields, createSchema, validate as _validate } from '..';

const {
  requiredText,
  thingsContent,
  optionalText,
  optionalArrayOfOptionalString,
  videoLinkType,
} = fields;

const volunteer = createSchema({
  title: requiredText,
  description: requiredText,
  thingsYouWillNeed: thingsContent,
  thingsThatMightBeUseful: thingsContent,
  whatHappensInThisStage: thingsContent,
  whatWillHappenNext: thingsContent,
  questionA: thingsContent,
  questionB: thingsContent,
  questionC: thingsContent,
  resources: thingsContent,
  topTip: optionalText,
  otherTips: optionalArrayOfOptionalString,
  videoLink: videoLinkType,
});

const validate = (data) => _validate(volunteer, data);

export default validate;
