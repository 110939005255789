import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid as G, Icon, Typography as T } from '../../../components';
import { navRoutes } from '../../../constants';
import { stageTypes } from '../../../constants/data-types';
import { useStages } from '../../../context/stages';
import { buttonStyle } from './style';

const ManageStages = () => {
  const { stages } = useStages();
  const navigate = useNavigate();

  const stagesWithoutAfterClaiming = useMemo(
    () =>
      stages.filter((stage) => stage.stageType !== stageTypes.AFTER_CLAIMING),
    [stages]
  );

  const afterClaimingStages = useMemo(
    () =>
      stages.filter((stage) => stage.stageType === stageTypes.AFTER_CLAIMING),
    [stages]
  );

  return (
    <div style={{ maxWidth: '900px' }}>
      <G.Row>
        <G.Col w={[4, 6, 6]}>
          <T.H1 style={{ width: '100%' }} mb="5">
            Edit Stage Content
          </T.H1>
        </G.Col>

        <G.Row mt="6" style={{ maxWidth: '700px' }}>
          {stagesWithoutAfterClaiming.map((stage) => {
            return (
              <div
                onClick={() =>
                  navigate(
                    navRoutes.SUPER_ADMIN.EDIT_STAGE.replace(':id', stage.id)
                  )
                }
                style={{
                  userSelect: 'none',
                  padding: 16,
                  margin: '0 0 8px 0',
                  minHeight: '50px',
                  backgroundColor: '#456C86',
                  color: 'white',
                  ...buttonStyle,
                }}
              >
                <T.P weight="bold">{stage.subtitle}</T.P>
                <Icon color="borderPrimary" icon="forwardArrow" />
              </div>
            );
          })}
        </G.Row>

        <G.Row mt="6" style={{ maxWidth: '700px' }}>
          <G.Col w={[4, 6, 6]}>
            <T.H4 style={{ width: '100%' }} mb="5">
              After application
            </T.H4>
          </G.Col>
          {afterClaimingStages.map((stage) => {
            return (
              <div
                onClick={() =>
                  navigate(
                    navRoutes.SUPER_ADMIN.EDIT_STAGE.replace(':id', stage.id)
                  )
                }
                style={{
                  userSelect: 'none',
                  padding: 16,
                  margin: '0 0 8px 0',
                  minHeight: '50px',
                  backgroundColor: '#456C86',
                  color: 'white',
                  ...buttonStyle,
                }}
              >
                <T.P weight="bold">{stage.subtitle}</T.P>
                <Icon color="borderPrimary" icon="forwardArrow" />
              </div>
            );
          })}
        </G.Row>
      </G.Row>
    </div>
  );
};

export default ManageStages;
