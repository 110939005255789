const AR = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '18'}
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.429993" width="24" height="17.1429" rx="2" fill="white" />
    <mask
      id="mask0_4870_6973"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="18"
    >
      <rect y="0.429993" width="24" height="17.1429" rx="2" fill="white" />
    </mask>
    <g mask="url(#mask0_4870_6973)">
      <rect y="0.429993" width="24" height="17.1429" fill="#128C4B" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.71424 10.7157H14.8571V10.0801C14.8571 9.5845 15.3786 9.26217 15.8219 9.4838L16 9.57282V10.7157H17.619C17.9872 10.7157 18.2857 11.0142 18.2857 11.3823V11.6681C18.2857 12.4044 17.6887 13.0014 16.9523 13.0014H15.5238C15.1556 13.0014 14.8571 12.7029 14.8571 12.3347V11.8585H7.13324C6.95643 11.8585 6.78686 11.7883 6.66184 11.6633L5.71424 10.7157Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.71424 7.42519C5.71424 7.33678 5.74936 7.252 5.81187 7.18949L6.04997 6.95139C6.18014 6.82122 6.18014 6.61016 6.04997 6.47999L5.81187 6.24189C5.74936 6.17938 5.71424 6.0946 5.71424 6.00619V5.33474C5.71424 5.15064 5.86348 5.0014 6.04757 5.0014H6.63883C6.75938 5.0014 6.8571 5.09912 6.8571 5.21967C6.8571 5.44999 7.16759 5.52329 7.27059 5.31728L7.35577 5.14691C7.40036 5.05774 7.49151 5.0014 7.59121 5.0014H8.8571C9.01489 5.0014 9.14281 5.12932 9.14281 5.28712C9.14281 5.44491 9.01489 5.57283 8.8571 5.57283H8.28567C8.12787 5.57283 7.99996 5.70075 7.99996 5.85855C7.99996 6.01634 8.12787 6.14426 8.28567 6.14426H8.80948C8.99357 6.14426 9.14281 6.2935 9.14281 6.47759V8.09664C9.14281 8.28074 8.99357 8.42998 8.80948 8.42998H8.33329C8.14919 8.42998 7.99996 8.28074 7.99996 8.09664V7.57283C7.99996 7.41504 8.12787 7.28712 8.28567 7.28712C8.44347 7.28712 8.57138 7.1592 8.57138 7.0014C8.57138 6.84361 8.44347 6.71569 8.28567 6.71569H7.76186C7.57777 6.71569 7.42853 6.86493 7.42853 7.04902V8.14426C7.42853 8.30206 7.30061 8.42998 7.14281 8.42998C6.98502 8.42998 6.8571 8.30206 6.8571 8.14426V7.57283C6.8571 7.41504 6.72918 7.28712 6.57138 7.28712C6.41359 7.28712 6.28567 7.41504 6.28567 7.57283V8.14426C6.28567 8.30206 6.15775 8.42998 5.99996 8.42998C5.84216 8.42998 5.71424 8.30206 5.71424 8.14426V7.42519Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8572 7.42519C14.8572 7.33678 14.8923 7.252 14.9548 7.18949L15.1929 6.95139C15.3231 6.82122 15.3231 6.61016 15.1929 6.47999L14.9548 6.24189C14.8923 6.17938 14.8572 6.0946 14.8572 6.00619V5.33474C14.8572 5.15064 15.0064 5.0014 15.1905 5.0014H15.7818C15.9023 5.0014 16 5.09912 16 5.21967C16 5.44999 16.3105 5.52329 16.4135 5.31728L16.4987 5.14691C16.5433 5.05774 16.6345 5.0014 16.7342 5.0014H18C18.1578 5.0014 18.2858 5.12932 18.2858 5.28712C18.2858 5.44491 18.1578 5.57283 18 5.57283H17.4286C17.2708 5.57283 17.1429 5.70075 17.1429 5.85855C17.1429 6.01634 17.2708 6.14426 17.4286 6.14426H17.9524C18.1365 6.14426 18.2858 6.2935 18.2858 6.47759V8.09664C18.2858 8.28074 18.1365 8.42998 17.9524 8.42998H17.4762C17.2921 8.42998 17.1429 8.28074 17.1429 8.09664V7.57283C17.1429 7.41504 17.2708 7.28712 17.4286 7.28712C17.5864 7.28712 17.7143 7.1592 17.7143 7.0014C17.7143 6.84361 17.5864 6.71569 17.4286 6.71569H16.9048C16.7207 6.71569 16.5715 6.86493 16.5715 7.04902V8.14426C16.5715 8.30206 16.4436 8.42998 16.2858 8.42998C16.128 8.42998 16 8.30206 16 8.14426V7.57283C16 7.41504 15.8721 7.28712 15.7143 7.28712C15.5565 7.28712 15.4286 7.41504 15.4286 7.57283V8.14426C15.4286 8.30206 15.3007 8.42998 15.1429 8.42998C14.9851 8.42998 14.8572 8.30206 14.8572 8.14426V7.42519Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7143 5.85587C13.7143 6.01514 13.625 6.23355 13.508 6.35056L13.3492 6.50939C13.2352 6.62333 13.2321 6.80498 13.3492 6.92199L13.508 7.08082C13.6219 7.19475 13.7143 7.40942 13.7143 7.57551V8.14158C13.7143 8.30086 13.592 8.42998 13.4259 8.42998H12.8598C12.7005 8.42998 12.5714 8.30767 12.5714 8.14158V7.57551C12.5714 7.41624 12.5187 7.39259 12.4389 7.55218L12.1325 8.16491C12.0593 8.3113 11.8655 8.42998 11.7169 8.42998H10.5688C10.4125 8.42998 10.2857 8.29744 10.2857 8.14426C10.2857 7.98647 10.408 7.85855 10.5741 7.85855H11.1402C11.2995 7.85855 11.4286 7.72601 11.4286 7.57283C11.4286 7.41504 11.3063 7.28712 11.1402 7.28712H10.5741C10.4148 7.28712 10.2857 7.16423 10.2857 7.00684V5.28168C10.2857 5.12689 10.408 5.0014 10.5741 5.0014H11.1402C11.2995 5.0014 11.4286 5.12371 11.4286 5.28979V5.85587C11.4286 6.01514 11.296 6.14426 11.1429 6.14426C10.9851 6.14426 10.8571 6.27679 10.8571 6.42998C10.8571 6.58777 10.9794 6.71569 11.1455 6.71569H11.7116C11.8709 6.71569 12 6.58117 12 6.43261V5.28448C12 5.12814 12.1325 5.0014 12.2857 5.0014C12.4435 5.0014 12.5714 5.12371 12.5714 5.28979V5.85587C12.5714 6.01514 12.704 6.14426 12.8571 6.14426C13.0149 6.14426 13.1429 6.02196 13.1429 5.85587V5.28979C13.1429 5.13052 13.2754 5.0014 13.4286 5.0014C13.5864 5.0014 13.7143 5.12371 13.7143 5.28979V5.85587Z"
        fill="white"
      />
    </g>
  </svg>
);

export default AR;
