import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { Link } from 'react-router-dom';

import setMargin from '../../helpers/set-margin';

const bounce = (start) => keyframes`
  0% {
    background-color: currentColor;
  }

  100% {
    background-color: ${start};
  }
`;

export const Wrapper = styled.div`
  ${setMargin};
  width: 100%;
  background: ${({ theme, bgColor }) => theme.colors[bgColor]};
  display: flex;
  justify-content: center;
`;

export const OptionalContainer = styled.div`
  ${setMargin};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 720px;
  padding: ${({ theme: { spacings } }) =>
    `0 ${spacings[6]} ${spacings[4]} ${spacings[6]}`};

  ${({ theme }) => theme.media.mobile} {
    padding: ${({ theme: { spacings } }) => `0 ${spacings[4]}`};
    border-radius: 0px 8px 8px 0;
    max-width: 90vw;
    margin: 0;
  }
`;

export const StyledLink = styled(Link)`
  color: transparent;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacings[4]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  border: none;

  position: relative;
  max-width: 720px;
  padding-top: ${({ titleLength, isFontLarge }) =>
    titleLength && isFontLarge ? '40px' : titleLength ? '30px' : '20px'};
  ${({ theme }) => theme.media.mobile} {
    width: auto;
    padding-top: ${({ titleLength, isFontLarge }) =>
      titleLength && isFontLarge ? '40px' : titleLength ? '30px' : '20px'};
  }
`;

export const AngledBanner = styled.div`
  max-width: ${({ isFontLarge }) => (isFontLarge ? '550px' : '400px')};
  min-height: 46px;
  padding-left: ${({ theme }) => theme.spacings[4]};
  padding-right: ${({ theme }) => theme.spacings[4]};

  border-radius: 8px;
  background: ${({ theme, bannerColor }) => theme.colors[bannerColor]};
  position: absolute;
  top: ${({ isFontLarge }) => (isFontLarge ? '-18%' : '-20%')};
  right: -20px;

  right: ${({ direction }) => (direction === 'right' ? '-10%' : null)};
  left: ${({ direction }) => (direction === 'left' ? '-10%' : null)};
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: ${({ direction }) => (direction === 'right' ? 'end' : 'start')};
  flex-direction: column;
  top: 0;
  transform: translate(0, -60%);

  &:after {
    content: '';
    position: absolute;
    left: ${({ direction }) => (direction === 'left' ? '10%' : null)};
    right: ${({ direction }) => (direction === 'right' ? '10%' : null)};
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    transform: ${({ direction }) =>
      direction === 'left' ? 'skewX(-29deg)' : 'skewX(29deg)'};
    -webkit-transform: ${({ direction }) =>
      direction === 'left' ? 'skewX(-29deg)' : 'skewX(29deg)'};
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    z-index: -1;
  }

  ${({ theme }) => theme.media.mobile} {
    max-width: ${({ isFontLarge }) => (isFontLarge ? '400px' : '300px')};
    right: ${({ direction }) => (direction === 'right' ? 0 : null)};
    left: ${({ direction }) => (direction === 'left' ? 0 : null)};
  }

  ${({ theme }) => theme.media.tablet} {
    right: ${({ direction }) => (direction === 'right' ? 0 : null)};
    left: ${({ direction }) => (direction === 'left' ? 0 : null)};
  }
`;

export const Strong = styled.strong`
  font-weight: !important;
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
`;
