import { useState } from 'react';
import * as S from './style';
import * as T from '../../Typography';
import { Checkbox } from '../../Inputs';
import TextWithIcon from '../../TextWithIcon';
import Tips from '../Tips';
import { common } from '../../../constants';
import { useTranslation } from 'react-i18next';

const Checklist = ({
  title,
  name,
  description,
  thisCanInclude,
  tips,
  handleChange,
  completed,
}) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const seeMore = t('common.buttons.seeMore', common.buttons.seeMore);
  const seeLess = t('common.buttons.seeLess', common.buttons.seeLess);
  const seeMoreOrLess = expanded ? seeLess : seeMore;
  const _thisCanInclude = thisCanInclude?.length ? thisCanInclude : [];
  const _description = description.length ? description : [];

  return (
    <>
      <S.Section mb="3">
        <S.TopSection>
          <Checkbox
            label={
              <T.P weight="bold" mt="2" ml="1" color="neutralMain">
                {title}
              </T.P>
            }
            handleChange={handleChange}
            name={name}
            checked={completed}
            m={{ mb: '5' }}
          />
          {(description?.length > 0 ||
            _thisCanInclude?.length > 0 ||
            tips?.length > 0) && (
            <TextWithIcon
              text={seeMoreOrLess}
              isButton
              mt="4"
              color="neutralMain"
              handleClick={() => setExpanded(!expanded)}
              mb={'5'}
              ml="6"
              iconProps={{
                icon: 'circleArrow',
                color: 'neutralMain',
                direction: expanded ? 'up' : 'down',
              }}
            />
          )}
        </S.TopSection>
        {expanded &&
          (_description?.filter((e) => !!e)?.length > 0 ||
            _thisCanInclude?.filter((e) => !!e)?.length > 0) && (
            <S.ExtraDetails>
              {_description?.filter((f) => !!f)?.length > 0 &&
                _description?.map((item, key) => (
                  <T.P color="neutralMain" mb="4">
                    {item}
                  </T.P>
                ))}
              {_thisCanInclude?.filter((v) => !!v)?.length > 0 && (
                <>
                  {_thisCanInclude
                    .filter((v) => !!v)
                    .map((thing, index) => (
                      <TextWithIcon
                        key={index}
                        text={thing}
                        color="neutralMain"
                        mb={index < _thisCanInclude.length && '2'}
                        ai="flex-start"
                        isText
                        iconProps={{
                          icon: 'bulletArrow',
                          color: 'neutralMain',
                          pointer: false,
                        }}
                      />
                    ))}
                </>
              )}
            </S.ExtraDetails>
          )}
      </S.Section>
      {expanded && tips && <Tips tips={tips} startingColor={2} mb="3" inner />}
    </>
  );
};

export default Checklist;
