import { validate as _validate, createSchema, fields } from '..';

const { password, firstName, lastName, agreedOnTerms, kidneyCareUkEmail } =
  fields;

const adminSignUpSchema = createSchema({
  email: kidneyCareUkEmail,
  firstName,
  lastName,
  password,
  agreedOnTerms,
});

const validate = (data) => {
  return _validate(adminSignUpSchema, data);
};

export default validate;
