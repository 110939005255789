import Logo from '../../components/assets/Logo.png';
import { Typography as T } from '../../components';
import { navRoutes } from '../../constants';
import Rhombus from './rhombus';

import * as S from './style';
import { useTranslation } from 'react-i18next';
import useLanguage from '../../helpers/use-language';
import { useMemo } from 'react';

import theme from '../../theme';

const Header = ({ uniqueSlug, publicOrg, title, subtitle, error }) => {
  const { i18n } = useTranslation();
  const { lng } = useLanguage();

  const dir = useMemo(() => i18n.dir(), [i18n, lng]); // Don't drop lng dep

  return (
    <S.HeaderContainer>
      <S.PageHead>
        <S.RhombusWrapper>
          <Rhombus
            height="100%"
            width="100%"
            color={theme.colors.secondaryMain}
          />
        </S.RhombusWrapper>
        <S.HeaderContent>
          <S.LogoContainer
            to={navRoutes.GENERAL.HOME_ORG.replace(
              ':uniqueSlug',
              publicOrg.uniqueSlug
            )}
          >
            <img src={publicOrg?.logoUrl || Logo} alt="logo" />
          </S.LogoContainer>
          <S.HeaderText>
            {error ? (
              <T.H4 color="error" mb={2}>
                {error}
              </T.H4>
            ) : (
              <>
                <T.H4 weight="bold" color="white" mb={2} dir={dir}>
                  {title}
                </T.H4>
                {subtitle && (
                  <T.H4 weight="bold" color="white" mb={2} dir={dir}>
                    {subtitle}
                  </T.H4>
                )}
              </>
            )}
            <S.HorizontalBar />
          </S.HeaderText>
        </S.HeaderContent>
      </S.PageHead>
    </S.HeaderContainer>
  );
};

export default Header;
