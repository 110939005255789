import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Grid as G,
  Inputs as I,
  Modal,
  Typography as T,
} from '../../../components';

import GeneralTips from './GeneralTips';

import { Steps } from '../../../api-calls';
import { navRoutes } from '../../../constants';
import { stepTypes } from '../../../constants/data-types';
import { useAdminOrg } from '../../../context/admin-org';
import { StepForm as validate } from '../../../validation/schemas';
import DefaultStepSections from './DefaultStepSections';
import QuestionStepSections from './QuestionStepSections';

const initialState = {
  title: '',
  description: '',
  stepType: '',
  httpError: '',
  thingsYouWillNeed: [
    { title: '', description: [], thisCanInclude: [], tips: [] },
  ],
  thingsThatMightBeUseful: [
    { title: '', description: [], thisCanInclude: [], tips: [] },
  ],
  whatHappensInThisStage: [
    { title: '', description: [], thisCanInclude: [], tips: [] },
  ],
  whatWillHappenNext: [
    { title: '', description: [], thisCanInclude: [], tips: [] },
  ],
  resources: [{ title: '', description: [], thisCanInclude: [], tips: [] }],
  questionA: [{ title: '', description: [], thisCanInclude: [], tips: [] }],
  questionB: [{ title: '', description: [], thisCanInclude: [], tips: [] }],
  questionC: [{ title: '', description: [], thisCanInclude: [], tips: [] }],
  topTip: '',
  otherTips: [''],
  validationErrs: {},
  videoLink: '',
  loading: false,
};

const stepFormatOptions = [
  {
    label: 'Default',
    value: stepTypes.DEFAULT,
  },
  {
    label: 'Question',
    value: stepTypes.QUESTION,
  },
];

function reducer(state, newState) {
  return { ...state, ...newState };
}

const StepForm = () => {
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [isTypeChangeModalVisible, setIsTypeChangeModalVisible] =
    useState(false);
  const [isEmptySectionItems, setIsEmptySectionItems] = useState(false);

  const saveForPreview = useRef(false);
  const { adminOrg } = useAdminOrg();

  const submitAttempt = useRef(false);
  const [state, setState] = useReducer(reducer, initialState);
  const {
    title,
    description,
    stepType,
    thingsYouWillNeed,
    thingsThatMightBeUseful,
    whatHappensInThisStage,
    whatWillHappenNext,
    resources,
    questionA,
    questionB,
    questionC,
    validationErrs,
    httpError,
    otherTips,
    topTip,
    videoLink,
  } = state;

  const [unconfirmedStepType, setUnconfirmedStepType] = useState(null);

  const { id: stepId } = useParams();

  const getStepData = useCallback(async () => {
    setState({ loading: true });
    const { error, data } = await Steps.getStepById({
      id: stepId,
      lng: 'en',
      forPublic: false,
    });

    setState({ loading: false });
    if (error) {
      return setState({ httpError: error.message });
    }
    setState({
      ...data,
      thingsYouWillNeed: data?.thingsYouWillNeed?.length
        ? data.thingsYouWillNeed
        : initialState.thingsYouWillNeed,
      thingsThatMightBeUseful: data?.thingsThatMightBeUseful?.length
        ? data.thingsThatMightBeUseful
        : initialState.thingsThatMightBeUseful,
      whatHappensInThisStage: data?.whatHappensInThisStage?.length
        ? data.whatHappensInThisStage
        : initialState.whatHappensInThisStage,
      whatWillHappenNext: data?.whatWillHappenNext?.length
        ? data.whatWillHappenNext
        : initialState.whatWillHappenNext,
      resources: data?.resources?.length
        ? data.resources
        : initialState.resources,
      questionA: data?.questionA?.length
        ? data.questionA
        : initialState.questionA,
      questionB: data?.questionB?.length
        ? data.questionB
        : initialState.questionB,
      questionC: data?.questionC?.length
        ? data.questionC
        : initialState.questionC,
      otherTips: data?.otherTips?.length
        ? data.otherTips
        : initialState.otherTips,
    });
  }, [stepId]);

  useEffect(() => {
    getStepData();
  }, [getStepData]);

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    title,
    description,
    thingsYouWillNeed,
    thingsThatMightBeUseful,
    whatHappensInThisStage,
    whatWillHappenNext,
    questionA,
    questionB,
    questionC,
    resources,
    topTip,
    otherTips,
    videoLink,
  ]);

  const validateForm = useCallback(() => {
    try {
      const filteredSections = {
        thingsYouWillNeed: thingsYouWillNeed.filter(
          (e, i) => !(i === thingsYouWillNeed.length - 1 && !e.title)
        ),
        thingsThatMightBeUseful: thingsThatMightBeUseful.filter(
          (e, i) => !(i === thingsThatMightBeUseful.length - 1 && !e.title)
        ),
        whatHappensInThisStage: whatHappensInThisStage.filter(
          (e, i) => !(i === whatHappensInThisStage.length - 1 && !e.title)
        ),
        whatWillHappenNext: whatWillHappenNext.filter(
          (e, i) => !(i === whatWillHappenNext.length - 1 && !e.title)
        ),
        resources: resources.filter(
          (e, i) => !(i === resources.length - 1 && !e.title)
        ),
        questionA: questionA.filter(
          (e, i) => !(i === questionA.length - 1 && !e.title)
        ),
        questionB: questionB.filter(
          (e, i) => !(i === questionB.length - 1 && !e.title)
        ),
        questionC: questionC.filter(
          (e, i) => !(i === questionC.length - 1 && !e.title)
        ),
      };

      validate({
        ...state,
        ...filteredSections,
        otherTips: otherTips.filter(
          (e, i) => !(i === otherTips.length - 1 && !e.title)
        ),
      });

      if (Object.values(filteredSections).every((section) => !section.length)) {
        setIsEmptySectionItems(true);
        return false;
      } else {
        setIsEmptySectionItems(false);
      }

      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  }, [
    state,
    otherTips,
    thingsYouWillNeed,
    thingsThatMightBeUseful,
    whatHappensInThisStage,
    whatWillHappenNext,
    resources,
    questionA,
    questionB,
    questionC,
  ]);

  const handleEditStep = useCallback(async () => {
    setState({ loading: true });

    const { error } = await Steps.editStep({
      id: stepId,
      form: {
        ...state,
        thingsYouWillNeed: thingsYouWillNeed.filter((e) => !!e.title),
        thingsThatMightBeUseful: thingsThatMightBeUseful.filter(
          (e) => !!e.title
        ),
        whatHappensInThisStage: whatHappensInThisStage.filter((e) => !!e.title),
        whatWillHappenNext: whatWillHappenNext.filter((e) => !!e.title),
        resources: resources.filter((e) => !!e.title),
        questionA: questionA.filter((e) => !!e.title),
        questionB: questionB.filter((e) => !!e.title),
        questionC: questionC.filter((e) => !!e.title),
        otherTips: otherTips.filter((e, i) => !!e),
      },
    });

    setState({ loading: false });
    if (error) {
      setState({ httpError: error.message });
    } else {
      if (saveForPreview.current) {
        window.open(
          navRoutes.GENERAL.STEP_ORG.replace(
            ':uniqueSlug',
            adminOrg.uniqueSlug
          ).replace(':id', stepId),
          '_blank'
        );
      } else {
        setIsSuccessModalVisible(true);
      }
      // after that the user should be directed to its dashboard
      // history.push(R.ADMIN.HOME);
    }
    saveForPreview.current = false;
  }, [
    adminOrg.uniqueSlug,
    otherTips,
    state,
    stepId,
    thingsYouWillNeed,
    thingsThatMightBeUseful,
    whatHappensInThisStage,
    whatWillHappenNext,
    resources,
    questionA,
    questionB,
    questionC,
  ]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      submitAttempt.current = true;

      const isValid = validateForm();
      if (isValid) {
        handleEditStep();
      }
    },
    [handleEditStep, validateForm]
  );

  return (
    <G.Row>
      <G.Row>
        <G.Col w={[12, 12, 12]}>
          <T.H1 mb="6">Edit Step Content</T.H1>
        </G.Col>
      </G.Row>
      <G.Row>
        <G.Col w={[4, 6, 6]}>
          <I.Dropdown
            name="stepType"
            label="Step Format"
            selected={stepType}
            options={stepFormatOptions}
            error={validationErrs.stepType}
            handleChange={(value) => {
              setIsTypeChangeModalVisible(true);
              setUnconfirmedStepType(value);
            }}
          />
        </G.Col>
      </G.Row>
      <G.Row mt="5">
        <G.Col w={[4, 6, 6]}>
          <I.BasicInput
            name="title"
            placeholder="Type title here..."
            label="Title"
            error={validationErrs.title}
            value={title}
            handleChange={(input) => setState({ title: input })}
          />
        </G.Col>
      </G.Row>
      <G.Row mt="5">
        <G.Col w={[4, 12, 12]}>
          <I.Textarea
            name="description"
            value={description}
            placeholder="Type description here..."
            label="Description"
            handleChange={(input) => setState({ description: input })}
            rows="4"
            error={validationErrs.description}
          />
        </G.Col>
      </G.Row>
      <G.Row mt="5">
        <G.Col w={[4, 8, 8]}>
          <I.BasicInput
            name="videoLink"
            placeholder="Type/paste link here..."
            label="Video Link"
            optional
            error={validationErrs.videoLink}
            value={videoLink}
            handleChange={(input) => setState({ videoLink: input })}
          />
        </G.Col>
      </G.Row>
      <G.Row>
        <G.Col w={[4, 6, 4]}></G.Col>
      </G.Row>

      {stepType === stepTypes.DEFAULT && (
        <DefaultStepSections
          state={state}
          setState={setState}
          validationErrs={validationErrs}
        />
      )}

      {stepType === stepTypes.QUESTION && (
        <QuestionStepSections
          state={state}
          setState={setState}
          validationErrs={validationErrs}
        />
      )}

      <GeneralTips
        otherTips={otherTips}
        topTip={topTip}
        setState={setState}
        errors={validationErrs}
      />
      <G.Row mt="10">
        {httpError && (
          <G.Col w={[4, 12, 12]}>
            <T.P mb="2" color="error">
              {httpError}
            </T.P>
          </G.Col>
        )}
        {Object.values(validationErrs)?.length ? (
          <G.Col w={[4, 12, 12]}>
            <T.P mb="2" color="error">
              At least one of the input fields has not been filled in or details
              entered incorrectly. Please check the form above for more details.
            </T.P>
            {isEmptySectionItems && (
              <T.P mb="2" color="error">
                You must provide at least one advice item to this step.
              </T.P>
            )}
          </G.Col>
        ) : null}
        {isEmptySectionItems && (
          <G.Col w={[4, 12, 12]}>
            {isEmptySectionItems && (
              <T.P mb="2" color="error">
                You must provide at least one advice item to this step.
              </T.P>
            )}
          </G.Col>
        )}
        <G.Col w={[4, 6, 4]}>
          <Button
            text="Save"
            handleClick={handleSubmit}
            mb="4"
            mt="4"
            loading={state.loading}
          />
        </G.Col>
        <G.Col w={[4, 6, 4]}>
          <Button
            text="Save and preview"
            handleClick={(e) => {
              saveForPreview.current = true;
              handleSubmit(e);
            }}
            variant="secondary"
            mb="4"
            mt="4"
            loading={state.loading}
          />
        </G.Col>
      </G.Row>
      <Modal
        visible={isSuccessModalVisible}
        setIsModalVisible={setIsSuccessModalVisible}
        parentFunc={() => {}}
        type="updateSuccess"
        title="Updated"
        description="Changes successfully updated."
        btnText="Okay"
      />
      <Modal
        visible={isTypeChangeModalVisible}
        setIsModalVisible={setIsTypeChangeModalVisible}
        parentFunc={() => {
          if (unconfirmedStepType) {
            let newState = { stepType: unconfirmedStepType };

            if (unconfirmedStepType === stepTypes.QUESTION) {
              newState = {
                ...newState,
                thingsYouWillNeed: [],
                thingsThatMightBeUseful: [],
                whatHappensInThisStage: [],
                whatWillHappenNext: [],
                resources: [],
              };
            } else {
              newState = {
                ...newState,
                questionA: [],
                questionB: [],
                questionC: [],
              };
            }

            setState(newState);
          }
        }}
        title="Are you sure?"
        description="This will change the format of the step and so any content currently there will be deleted."
        btnText="Confirm"
      />
    </G.Row>
  );
};

export default StepForm;
