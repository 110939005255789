const Flag = ({ width, height, color, ...props }) => (
  <svg
    width="291"
    height="47"
    viewBox="0 0 291 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      y1="22.5"
      x2="76"
      y2="22.5"
      stroke={color || '#FC6244'}
      strokeWidth="2"
    />
    <path
      d="M198.818 17.9989L161.946 0L149.727 25.0338L149.007 26.5076L139.344 46.3074L140.762 47L150.426 27.2002L185.881 44.5065L198.818 17.9989ZM175.243 25.9535L170.177 23.4816L172.559 18.6031L177.625 21.0749L175.243 25.9535ZM180.24 28.5638L177.858 33.4424L172.793 30.9706L175.175 26.092L180.24 28.5638ZM165.114 21.0081L160.048 18.5363L162.43 13.6577L167.495 16.1295L165.114 21.0081ZM170.111 23.6185L167.729 28.4971L162.663 26.0252L165.045 21.1467L170.111 23.6185ZM157.576 23.6018L162.642 26.0736L160.539 30.3814L155.473 27.9096L157.576 23.6018ZM153.243 21.4271L155.625 16.5485L159.981 18.6748L157.6 23.5534L153.243 21.4271ZM167.706 28.5455L172.771 31.0173L170.668 35.3251L165.603 32.8533L167.706 28.5455ZM177.835 33.4908L182.901 35.9627L180.798 40.2705L175.732 37.7986L177.835 33.4908ZM180.307 28.4253L182.689 23.5467L187.754 26.0186L185.372 30.8972L180.307 28.4253ZM182.924 35.9143L185.306 31.0357L189.662 33.162L187.28 38.0406L182.924 35.9143ZM187.777 25.9702L190.226 20.9531L185.16 18.4812L182.712 23.4983L177.646 21.0265L180.095 16.0094L175.029 13.5375L172.581 18.5547L167.515 16.0828L169.964 11.0657L164.898 8.59386L162.45 13.611L158.094 11.4846L160.542 6.46751L164.898 8.59386L167.025 4.23768L172.09 6.70952L169.964 11.0657L175.029 13.5375L177.156 9.18136L182.221 11.6532L180.095 16.0094L185.16 18.4812L187.287 14.125L192.352 16.5969L190.226 20.9531L194.582 23.0794L192.134 28.0965L187.777 25.9702Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M104.938 44.5065L140.394 27.2002L150.057 47L151.476 46.3074L141.812 26.5076L141.093 25.0338L128.872 0L92 17.9989L104.938 44.5065ZM113.195 21.0749L118.261 18.6031L120.642 23.4816L115.577 25.9535L113.195 21.0749ZM115.644 26.0903L118.025 30.9689L112.96 33.4408L110.578 28.5622L115.644 26.0903ZM123.324 16.1295L128.39 13.6577L130.772 18.5363L125.706 21.0081L123.324 16.1295ZM125.773 21.1467L128.155 26.0252L123.089 28.4971L120.707 23.6185L125.773 21.1467ZM135.345 27.9079L130.279 30.3798L128.176 26.072L133.242 23.6001L135.345 27.9079ZM133.218 23.5534L130.837 18.6748L135.193 16.5485L137.575 21.4271L133.218 23.5534ZM125.215 32.8533L120.15 35.3251L118.047 31.0173L123.112 28.5455L125.215 32.8533ZM115.084 37.7969L110.019 40.2688L107.916 35.961L112.981 33.4892L115.084 37.7969ZM105.446 30.8972L103.064 26.0186L108.13 23.5467L110.511 28.4253L105.446 30.8972ZM103.538 38.039L101.156 33.1604L105.512 31.034L107.894 35.9126L103.538 38.039ZM98.6845 28.0965L96.236 23.0794L100.592 20.9531L98.4658 16.5969L103.531 14.125L105.658 18.4812L110.723 16.0094L108.597 11.6532L113.662 9.18136L115.789 13.5375L120.854 11.0657L118.728 6.70952L123.793 4.23768L125.92 8.59386L130.276 6.46751L132.724 11.4846L128.368 13.611L125.92 8.59386L120.854 11.0657L123.303 16.0828L118.237 18.5547L115.789 13.5375L110.723 16.0094L113.172 21.0265L108.106 23.4983L105.658 18.4812L100.592 20.9531L103.041 25.9702L98.6845 28.0965Z"
      fill="url(#paint1_linear)"
    />
    <line
      x1="214.818"
      y1="22.5"
      x2="290.818"
      y2="22.5"
      stroke={color || '#FC6244'}
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="139.344"
        y1="24.7123"
        x2="198.16"
        y2="24.7123"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1A202B" />
        <stop offset="1" stopColor="#3B557A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="92"
        y1="24.7123"
        x2="150.818"
        y2="24.7123"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1A202B" />
        <stop offset="1" stopColor="#3B557A" />
      </linearGradient>
    </defs>
  </svg>
);

export default Flag;
