import styled from '@emotion/styled';
import * as T from '../../components/Typography';

export const StyledText = styled(T.P)`
  color: ${({ theme: { colors } }) => colors.neutralMain};
  width: 100%;
  text-align: ${({ align }) => `${align}!important` || ''};
`;

export const StyledTitle = styled(T.H4)`
  color: ${({ theme: { colors } }) => colors.neutralMain};
  width: 100%;
  text-align: ${({ align }) => `${align}!important` || ''};
`;
export const HorizontalDivider = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : '2px')};
  background-color: ${({ theme, color }) => color || theme.colors.primaryMain};
  margin: ${({ theme }) => `${theme.spacings[5]} auto`};
`;

export const HyperLink = styled.a`
  text-decoration: underline !important;
  color: ${({ theme }) => theme.colors.neutralMain} !important;
`;
