import { message } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LandingPage } from '../../api-calls';
import { Header } from '../../components';
import { common } from '../../constants';
import { usePublicOrg } from '../../context/public-org';
import { useLanguage } from '../../helpers';

import { useTheme } from '@emotion/react';
import * as S from './style';

const LandingContent = ({ uniqueSlug }) => {
  const { t, i18n } = useTranslation();
  const { lng } = useLanguage();
  const theme = useTheme();

  const dir = useMemo(() => i18n.dir(), [i18n, lng]); // Don't drop lng dep

  const { publicOrg } = usePublicOrg();
  const [landingContent, setLandingContent] = useState({});
  const [fetchError, setFetchError] = useState('');

  const fetchData = useCallback(
    async (mounted) => {
      const hideMessage = message.loading('Loading...', 0);
      const { data, error } = await LandingPage.getLandingPageContent({
        lng,
        forPublic: true,
      });
      if (mounted) {
        if (error) {
          setFetchError(t(`generalError`, lng));
        } else {
          setLandingContent(data);
        }
        hideMessage();
      }
    },
    [lng, t]
  );

  useEffect(() => {
    let mounted = true;
    fetchData(mounted);
    return () => {
      mounted = false;
    };
  }, [fetchData]);

  return (
    <>
      <Header
        uniqueSlug={uniqueSlug}
        publicOrg={publicOrg}
        title={landingContent.headline}
        error={fetchError}
      />
      <S.Section dir={dir}>
        <S.StyledText strong mt="4">
          {landingContent.subtitle}
        </S.StyledText>
        <S.StyledText color={theme.colors.neutralGrey} mb="4">
          <div
            dangerouslySetInnerHTML={{
              __html: landingContent.instructions?.replace(/\n/g, '<br>'),
            }}
          ></div>
        </S.StyledText>
        {!!landingContent.isThisHelperForMe && (
          <>
            <S.StyledTitle>
              {t(
                'common.section.homePage.isThisHelperForMeTitle',
                common.section.homePage.isThisHelperForMeTitle
              )}
            </S.StyledTitle>
            <S.StyledText color={theme.colors.neutralGrey} mb="4">
              {landingContent.isThisHelperForMe}
            </S.StyledText>
          </>
        )}

        {!!landingContent.getStarted && (
          <>
            <S.StyledTitle>
              {t(
                'common.section.homePage.getStartedTitle',
                common.section.homePage.getStartedTitle
              )}
            </S.StyledTitle>
            <S.StyledText color={theme.colors.neutralGrey} mb="4">
              {landingContent.getStarted}
            </S.StyledText>
          </>
        )}
      </S.Section>
    </>
  );
};

export default LandingContent;
