const Phone = ({ width, height, color, ...props }) => (
  <svg
    width={width || '21'}
    height={height || '22'}
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.8282 21.5C15.9132 21.5 14.6278 21.1691 12.7032 20.0937C10.3627 18.7812 8.55238 17.5695 6.22457 15.2478C3.98019 13.0048 2.888 11.5526 1.35941 8.77109C-0.367464 5.63046 -0.0730895 3.98421 0.255973 3.28062C0.647848 2.43968 1.22629 1.93671 1.97394 1.43749C2.39861 1.15926 2.84801 0.920751 3.31644 0.724992C3.36332 0.704836 3.40691 0.685617 3.44582 0.668274C3.67785 0.563742 4.02941 0.405774 4.47472 0.574524C4.77191 0.686086 5.03722 0.914367 5.45254 1.32452C6.30425 2.16452 7.46816 4.0353 7.89754 4.95405C8.18582 5.57327 8.3766 5.98202 8.37707 6.44046C8.37707 6.97718 8.10707 7.39109 7.77941 7.8378C7.718 7.92171 7.65707 8.00187 7.598 8.07968C7.24129 8.54843 7.163 8.6839 7.21457 8.92577C7.3191 9.41187 8.09863 10.8589 9.37972 12.1372C10.6608 13.4155 12.0661 14.1458 12.5541 14.2498C12.8063 14.3037 12.9446 14.2222 13.4283 13.8528C13.4977 13.7998 13.5689 13.745 13.6435 13.6901C14.1432 13.3184 14.5378 13.0555 15.0619 13.0555H15.0647C15.5208 13.0555 15.9113 13.2533 16.5582 13.5795C17.4019 14.0051 19.3289 15.1541 20.1741 16.0067C20.5852 16.4211 20.8144 16.6855 20.9264 16.9822C21.0952 17.4289 20.9363 17.7791 20.8327 18.0134C20.8153 18.0523 20.7961 18.095 20.776 18.1423C20.5787 18.6099 20.3387 19.0584 20.0593 19.482C19.561 20.2273 19.0561 20.8044 18.2133 21.1967C17.7805 21.4014 17.3069 21.5051 16.8282 21.5Z"
      fill={color || '#E65D3E'}
    />
  </svg>
);

export default Phone;
