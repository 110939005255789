import { message } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Stages } from '../../api-calls';
import { ContactUs, Header, HelpButton, VideoPlayer } from '../../components';
import { SingleTip } from '../../components/Cards/Tips';
import { Col, Row } from '../../components/Grid';
import Step from '../../components/Steps';
import { navRoutes as n } from '../../constants';
import { usePublicOrg } from '../../context/public-org';
import { useStages } from '../../context/stages';
import { useSteps } from '../../context/steps';
import { useLanguage } from '../../helpers';
import * as S from './style';

const Stage = () => {
  const {
    fetchSteps,
    steps,
    justCompletedId,
    setJustCompletedId,
    loadingSteps,
    markAsInProgress: markAStepsInProgress,
  } = useSteps();

  const { stages, markAsCompleted, markAsInProgress } = useStages();

  const [stage, setStage] = useState({});
  const [stuck, setStuck] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const { lng } = useLanguage();
  const { publicOrg } = usePublicOrg();
  const { t } = useTranslation();

  const { uniqueSlug } = publicOrg;

  const stageId = useMemo(() => parseInt(params.id), [params.id]);
  const localStorageStage = stages.find((stage) => stage.id === stageId);

  const stageSteps = useMemo(
    () => steps.filter((step) => step.stageId === parseInt(stageId)),
    [steps, stageId]
  );

  const getStage = useCallback(async () => {
    const hideMessage = message.loading('Loading...', 0);

    const { data, error } = await Stages.getStageById({
      id: stageId,
      lng,
      forPublic: true,
    });

    hideMessage();
    if (error) {
      navigate(n.GENERAL.NOT_FOUND);
    }
    setStage(data);
  }, [lng, navigate, stageId]);

  const fetchStageSteps = useCallback(
    async (mounted) => {
      const hideMessage = message.loading('Loading...', 0);
      await fetchSteps(mounted, stageId);
      hideMessage();
    },
    [fetchSteps, stageId]
  );

  const decideRoute = useCallback(
    (step) =>
      generatePath(n.GENERAL.STEP_ORG, {
        uniqueSlug: publicOrg?.uniqueSlug,
        id: step.id,
      }),
    [publicOrg?.uniqueSlug]
  );

  const currentStep = useMemo(
    () => steps.find((step) => !!step.isInProgress && step.stageId === stageId),
    [steps, stageId]
  );

  const currentStepRef = useRef();

  const getStepStatus = useCallback(
    (step, i) => {
      const isCurrentStep = currentStep && step.id === currentStep.id;
      const variant = step.isCompleted
        ? 'tertiary'
        : isCurrentStep
        ? 'primary'
        : 'secondary';
      const isJustCompletedOne = step.id === justCompletedId;
      // To only add ref to the currentStep
      let currentRef = isCurrentStep ? currentStepRef : null;
      if (i === steps.length - 1 && step.isCompleted) {
        currentRef = currentStepRef;
      }

      return {
        variant,
        currentRef,
        isJustCompletedOne,
        isCurrentStep,
      };
    },
    [currentStep, justCompletedId, steps.length]
  );

  useEffect(() => {
    setTimeout(() => {
      currentStepRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, 350);
  }, []);

  useEffect(() => {
    getStage();
  }, [getStage]);

  useEffect(() => {
    const isInProgressStage =
      stageSteps?.length &&
      stageSteps.some((step) => step.isCompleted || step.isInProgress);
    const isCompletedStage =
      stageSteps?.length && stageSteps.every((step) => step.isCompleted);

    if (
      isCompletedStage &&
      !!localStorageStage &&
      !localStorageStage.isCompleted
    ) {
      markAsCompleted(stageId);
    }
    if (
      isInProgressStage &&
      !isCompletedStage &&
      !!localStorageStage &&
      !localStorageStage.isInProgress
    ) {
      markAsInProgress(stageId);
    }
  }, [
    stageSteps,
    stageId,
    markAsCompleted,
    localStorageStage,
    markAsInProgress,
  ]);

  useEffect(() => {
    let mounted = true;
    fetchStageSteps(mounted);
  }, [fetchStageSteps]);

  if (!stage) {
    navigate(n.GENERAL.NOT_FOUND);
    return null;
  }

  // navigate(`/success/${stage.id}`);

  return (
    <S.Container>
      <S.InnerContainer>
        <Header
          uniqueSlug={uniqueSlug}
          publicOrg={publicOrg}
          title={stage.headline}
          subtitle={stage.subtitle}
        />
      </S.InnerContainer>

      <S.InnerContainer thin>
        <S.StyledText my="5" mb={!stage.videoLink && '9'}>
          {stage.instructions}
        </S.StyledText>

        {stage.topTip && (
          <SingleTip
            tip={stage.topTip}
            icon="bulb"
            bgColor="secondaryLight"
            borderColor="secondaryMain"
            textColor="secondaryMain"
            iconColor="secondaryMain"
          />
        )}
      </S.InnerContainer>

      {stage.videoLink && (
        <S.InnerContainer thin my="5" mb="9">
          <VideoPlayer videoUrl={stage.videoLink} />
        </S.InnerContainer>
      )}

      <>
        {stageSteps.map((step, i) => {
          const { variant, currentRef, isJustCompletedOne } = getStepStatus(
            step,
            i
          );

          return (
            <Step
              key={step.id}
              title={step.title}
              description={step.description}
              content={t(`${step.name}.subtitle`, lng)}
              isCompleted={step.isCompleted}
              isInProgress={step.isInProgress}
              variant={variant}
              direction={i % 2 === 0 ? 'left' : 'right'}
              mt="7"
              isJustCompletedOne={isJustCompletedOne}
              to={decideRoute(step)}
              ref={currentRef}
              isOptional={step.isOptional}
              handleClick={() => {
                setJustCompletedId('');
                markAStepsInProgress(step.id);
              }}
              loadingSteps={loadingSteps}
            />
          );
        })}
      </>
      {stage.otherTips && (
        <S.InnerContainer>
          {stage.otherTips.map((item) => (
            <Row key={item}>
              <Col w={[4, 12, 12]} mt="6">
                <SingleTip
                  tip={item}
                  icon="bulb"
                  bgColor="secondaryLight"
                  borderColor="secondaryMain"
                  textColor="secondaryMain"
                  iconColor="secondaryMain"
                  mx="auto"
                  style={{ width: '100%' }}
                />
              </Col>
            </Row>
          ))}
        </S.InnerContainer>
      )}
      <S.InnerContainer thin>
        <ContactUs onClick={() => setStuck(true)} />
      </S.InnerContainer>
      <HelpButton parentState={stuck} parentFunc={() => setStuck(false)} />
    </S.Container>
  );
};

export default Stage;
