import { Grid as G, Typography as T, Inputs as I } from '../../../components';

const GeneralTips = ({ topTip, otherTips, setState, errors = {} }) => {
  return (
    <>
      <G.Row mt="8">
        <G.Col w={[4, 4, 4]}>
          <T.H2>General Tips</T.H2>
        </G.Col>
      </G.Row>
      <G.Row mt="6">
        <G.Col w={[4, 12, 6]}>
          <I.Textarea
            label="Top Tip"
            optional
            placeholder="type tip here..."
            helper="This is the most important tip, which you want to show at the top of the page"
            value={topTip}
            error={errors.topTip}
            autoSize={{ minRows: 2, maxRows: 15 }}
            handleChange={(input) => setState({ topTip: input })}
          />
        </G.Col>
        <G.Col w={[4, 12, 6]} mtT="6">
          <I.InputArray
            type="textarea"
            placeholder="type tip here..."
            values={otherTips}
            handleChange={(updateValues) =>
              setState({ otherTips: updateValues })
            }
            label="Other Tips"
            optional
            w={[4, 12, 12]}
            error={errors.otherTips}
          />
        </G.Col>
      </G.Row>
    </>
  );
};

export default GeneralTips;
