import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';
import * as T from '../../components/Typography';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacings[2]};
  width: 100%;

  ${({ theme }) => theme.media.tablet} {
    justify-content: start;
  }
`;

export const RhombusWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
`;

export const PageHead = styled.div`
  max-height: 290px;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const HeaderContent = styled.div`
  border-radius: ${({ theme: { spacings } }) => spacings[3]};
  position: relative;
  border-top-right-radius: ${({ theme: { spacings } }) => spacings[5]};
  background-color: ${({ theme: { colors } }) => colors.secondaryMain};
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  margin-bottom: 1px;

  display: flex;
  flex-direction: column;
  padding: ${({ theme: { spacings } }) => `${spacings[7]}`};
  padding-right: ${({ theme: { spacings } }) => spacings[3]};

  gap: ${({ theme }) => theme.spacings[8]};

  ${({ theme }) => theme.media.tablet} {
    gap: ${({ theme }) => theme.spacings[6]};
  }

  ${({ theme }) => theme.media.mobile} {
    padding: ${({ theme: { spacings } }) => `${spacings[6]}`};
    padding-right: ${({ theme: { spacings } }) => spacings[2]};
    width: 90%;
  }
`;

export const HeaderText = styled.div`
  width: 80%;
  ${({ theme }) => theme.media.tablet} {
    width: 90%;
  }
  ${({ theme }) => theme.media.mobile} {
    width: 90%;
  }
`;

export const HorizontalBar = styled.div`
  position: relative;
  display: inline-block;
  width: 90%;
  height: 6px;
  left: 20px;
  background-color: ${({ theme: { colors } }) => colors.primaryMain};

  &:after {
    height: inherit;
    position: absolute;
    content: '';
    left: -12px;
    width: 50px;
    background: inherit;
    transform: skew(-56deg);
  }
`;

export const LogoContainer = styled(Link)`
  width: 72px;
  height: 30px;
  margin: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const Section = styled.section`
  ${setMargin}
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-typography {
    max-width: 580px;
    padding: ${({ theme: { spacings } }) =>
      `${spacings[7]} ${spacings[6]} 0 ${spacings[6]}`};
  }
`;

export const StyledText = styled(T.P)`
  max-width: 580px;
  color: ${({ theme: { colors } }) => colors.neutralMain};
  padding: ${({ theme: { spacings } }) =>
    `${spacings[4]} ${spacings[6]} 0  ${spacings[6]}`};
`;

export const Span = styled.span`
  color: ${({ theme: { colors } }) => colors.neutralMain};
`;

export const Container = styled.div`
  ${setMargin};
  width: 100%;
  max-width: 580px;
  padding: ${({ theme: { spacings } }) => `${spacings[4]} ${spacings[6]}`};
  display: flex;
  justify-content: flex-start;
`;
