import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { common } from '../../constants';
import { EXTERNAL } from '../../constants/nav-routes';
import Button from '../Button';
import * as S from './style';

const ContactUs = ({
  variant = 'primary',
  onClick = () => {},
  divider = true,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      {divider && (
        <S.HorizontalDivider color={theme.colors.neutralLight} height={1} />
      )}

      <S.StyledTitle align="center" strong>
        {t(
          'common.section.homePage.feelingStuckTitle',
          common.section.homePage.feelingStuckTitle
        )}
      </S.StyledTitle>
      <Button
        text={t('common.buttons.giveUsACall', common.buttons.giveUsACall)}
        icon="phone"
        my="4"
        mx="auto"
        w="300px"
        onClick={onClick}
        variant={variant}
      />

      <S.StyledText strong mt="4" align="center" size="small">
        {t(
          'common.section.homePage.mentalHealthTipsText',
          common.section.homePage.mentalHealthTipsText
        )}
        <br />
        <S.HyperLink
          href={EXTERNAL.BUILDING_YOUR_EMOTIONAL_RESILIENCE}
          target="_blank"
        >
          {t(
            'common.section.homePage.mentalHealthTipsLinkText',
            common.section.homePage.mentalHealthTipsLinkText
          )}
        </S.HyperLink>
      </S.StyledText>
    </>
  );
};

export default ContactUs;
