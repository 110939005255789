const Accessibility = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={width || '24'}
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.76332 5.93333C9.24414 5.93333 8.73662 5.77938 8.30494 5.49094C7.87327 5.2025 7.53681 4.79253 7.33813 4.31288C7.13945 3.83322 7.08747 3.30542 7.18875 2.79622C7.29004 2.28702 7.54005 1.81929 7.90716 1.45218C8.27427 1.08507 8.742 0.835058 9.2512 0.733772C9.7604 0.632486 10.2882 0.68447 10.7679 0.88315C11.2475 1.08183 11.6575 1.41828 11.9459 1.84996C12.2344 2.28164 12.3883 2.78916 12.3883 3.30833C12.3876 4.0043 12.1108 4.67154 11.6186 5.16367C11.1265 5.65579 10.4593 5.93259 9.76332 5.93333Z"
      fill={color || '#1A202B'}
    />
    <path
      d="M18.0133 5.97083L17.9922 5.97646L17.9725 5.98255C17.9256 5.99568 17.8787 6.00974 17.8319 6.02427C16.9595 6.28021 12.7258 7.47364 9.74311 7.47364C6.97139 7.47364 3.12061 6.44239 1.88873 6.09224C1.76613 6.04483 1.64093 6.00445 1.51373 5.9713C0.623108 5.73693 0.0137329 6.64161 0.0137329 7.46849C0.0137329 8.28739 0.74967 8.67739 1.49264 8.95724V8.97036L5.95608 10.3644C6.41217 10.5393 6.53405 10.7179 6.59358 10.8726C6.78717 11.369 6.63248 12.3519 6.57764 12.695L6.30576 14.8044L4.79686 23.0633C4.79217 23.0858 4.78795 23.1088 4.7842 23.1322L4.77342 23.1918C4.66467 23.9488 5.22061 24.6833 6.27342 24.6833C7.19217 24.6833 7.59764 24.0491 7.77342 23.1861C7.9492 22.3232 9.08592 15.8 9.74217 15.8C10.3984 15.8 11.7503 23.1861 11.7503 23.1861C11.9261 24.0491 12.3315 24.6833 13.2503 24.6833C14.3059 24.6833 14.8619 23.9455 14.7503 23.1861C14.7407 23.1223 14.7288 23.0588 14.7147 22.9958L13.1851 14.8054L12.9137 12.696C12.7173 11.4674 12.8753 11.0615 12.9287 10.9663C12.9302 10.9641 12.9314 10.9617 12.9325 10.9593C12.9831 10.8655 13.2137 10.6555 13.7519 10.4535L17.9369 8.99052C17.9626 8.98367 17.9879 8.97554 18.0128 8.96614C18.7628 8.68489 19.5128 8.29583 19.5128 7.46943C19.5128 6.64302 18.9039 5.73693 18.0133 5.97083Z"
      fill={color || '#1A202B'}
    />
    <path
      d="M11.8023 3.27206C11.8023 4.41092 10.8885 5.33414 9.76129 5.33414C8.63406 5.33414 7.72025 4.41092 7.72025 3.27206C7.72025 2.1332 8.63406 1.20998 9.76129 1.20998C10.8885 1.20998 11.8023 2.1332 11.8023 3.27206Z"
      fill={color || '#1A202B'}
    />
  </svg>
);

export default Accessibility;
