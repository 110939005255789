import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';
import setMargin from '../../helpers/set-margin';
import { Typography } from 'antd';
const { Title, Paragraph } = Typography;

const weights = {
  bold: '700 !important',
  semi: '600 !important',
  medium: '500 !important',
  regular: '400 !important',
  light: '300 !important',
};

const decideSize = (size) => {
  switch (size) {
    case 'xsmall':
      return '0.90rem !important';
    case 'small':
      return '1rem !important';
    case 'large':
      return '1.25rem !important';
    case 'xl':
      return '1.375rem !important';
    default:
      return '1.125rem !important';
  }
};

const commonStyle = ({ theme, color, caps, ta, italic, ...props }) => `
  font-style:${italic ? 'italic' : 'normal'}  !important;
  font-family: ${({ theme }) => theme.layoutFontFamily || 'inherit'} !important;
  letter-spacing: 0.2px !important;
  color: ${
    color === 'neutralMain' && theme.layoutColor
      ? theme.colors.neutralMain
      : theme.colors[color] || color || theme.colors.neutralMain
  } !important;
  text-transform: ${caps ? 'uppercase' : 'initial'} !important;
  text-align: ${ta || 'start'} !important;
`;

const Head0 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-family: ${({ theme }) =>
    theme.layoutFontFamily || "'Nunito', sans-serif"};
  font-size: 2.5rem !important;
  line-height: auto !important;
  font-weight: ${({ weight }) => (weight ? weights[weight] : '700 !important')};

  ${({ theme }) => theme.media.mobile} {
    font-size: 1.5rem !important;
    line-height: auto !important;
  }
`;
export const H0 = (props) => <Head0 {...props} level={1} />;

const Head1 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-family: ${({ theme }) =>
    theme.layoutFontFamily || "'Nunito', sans-serif"};
  font-size: 2.75rem !important;
  line-height: 130% !important;
  font-weight: ${({ weight }) => (weight ? weights[weight] : '700 !important')};

  ${({ theme }) => theme.media.mobile} {
    font-size: 1.75rem !important;
    line-height: 140% !important;
  }
`;
export const H1 = (props) => <Head1 {...props} level={1} />;

const Head2 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-family: ${({ theme }) =>
    theme.layoutFontFamily || "'Nunito', sans-serif"};
  font-size: 2.25rem !important;
  line-height: 140% !important;
  font-weight: ${({ weight }) => (weight ? weights[weight] : '600 !important')};

  ${({ theme }) => theme.media.mobile} {
    font-size: 1.5rem !important;
    line-height: 140% !important;
  }
`;
export const H2 = (props) => <Head2 {...props} level={2} />;

const Head3 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-family: ${({ theme }) =>
    theme.layoutFontFamily || "'Nunito', sans-serif"};
  font-size: 1.75rem !important;
  line-height: 140% !important;
  font-weight: ${({ weight }) => (weight ? weights[weight] : '600 !important')};
  text-decoration: ${({ td }) => td || 'none'};
  ${({ theme }) => theme.media.tablet} {
    font-size: 1.375rem !important;
    line-height: 140% !important;
  }
  ${({ theme }) => theme.media.mobile} {
    font-size: 1.375rem !important;
    line-height: 140% !important;
  }
`;
export const H3 = (props) => <Head3 {...props} level={3} />;

const Head4 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-family: ${({ theme }) =>
    theme.layoutFontFamily || "'Nunito', sans-serif"};
  font-size: 1.5rem !important;
  line-height: 140% !important;
  font-weight: ${({ weight }) => (weight ? weights[weight] : '600 !important')};
  text-decoration: ${({ td }) => td || 'none'};
  ${({ theme }) => theme.media.mobile} {
    font-size: 1.25rem !important;
    line-height: 140% !important;
  }
`;
export const H4 = (props) => <Head4 {...props} level={4} />;

const Head5 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-family: ${({ theme }) =>
    theme.layoutFontFamily || "'Nunito', sans-serif"};
  font-size: 1.375rem !important;
  line-height: 140% !important;
  font-weight: ${({ weight }) => (weight ? weights[weight] : '600 !important')};
  text-decoration: ${({ td }) => td || 'none'};
  ${({ theme }) => theme.media.mobile} {
    font-size: 1.25rem !important;
    line-height: 140% !important;
  }
`;
export const H5 = (props) => <Head5 {...props} level={5} />;

const Head6 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-family: ${({ theme }) =>
    theme.layoutFontFamily || "'Nunito', sans-serif"};
  font-size: 1.25rem !important;
  line-height: 140% !important;
  font-weight: ${({ weight }) => (weight ? weights[weight] : '600 !important')};
  text-decoration: ${({ td }) => td || 'none'};
`;
export const H6 = (props) => <Head6 {...props} level={6} />;

export const P = styled(({ size, ...props }) => {
  return <Paragraph {...props} />;
})`
  ${setMargin};
  ${commonStyle};
  line-height: 24px !important;
  font-weight: ${({ weight }) => (weight ? weights[weight] : '400 !important')};
  pre {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
  }

  font-size: ${({ size }) => decideSize(size)};
  line-height: 160% !important;
`;

const AntdLink = ({ to, external = false, underline, ...props }) => {
  return external ? (
    <Typography.Link target="_blank" href={to} {...props} />
  ) : (
    <RouterLink to={to} {...props}>
      {props.children}
    </RouterLink>
  );
};
export const Link = styled(AntdLink)`
  ${setMargin};
  ${commonStyle};
  font-size: ${({ size }) => decideSize(size)};
  line-height: 160% !important;
  font-weight: ${({ weight }) => (weight ? weights[weight] : '400 !important')};

  text-decoration: ${({ underline }) =>
    underline ? 'underline' : 'none'} !important;
`;
