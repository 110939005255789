import { useMemo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useStages } from '../../context/stages';
import { useSteps } from '../../context/steps';
import { usePublicOrg } from '../../context/public-org';
import { common, navRoutes as n } from '../../constants';
import * as S from './style';

import { Grid, Typography as T, Icon, Header, Button } from '../../components';

const { Row, Col } = Grid;

const Success = () => {
  const navigate = useNavigate();
  const {
    publicOrg,
    publicOrg: { uniqueSlug },
  } = usePublicOrg();
  const { id: stageId } = useParams();
  const { stages, markSuccessAsShown } = useStages();
  const { steps } = useSteps();

  const { t } = useTranslation();

  const localStorageStage = stages.find(
    (stage) => stage.id === Number(stageId)
  );

  const stageSteps = useMemo(
    () => steps.filter((_step) => _step?.stageId === Number(stageId)),
    [stageId, steps]
  );

  const hasRun = useRef(false);

  const totalStages = stages.length;

  useEffect(() => {
    if (!localStorageStage) navigate('/');

    if (hasRun.current) return;

    const isCompletedStage = stageSteps.every(
      (step) => step.isCompleted === true
    );
    if (
      localStorageStage?.successShown ||
      (!isCompletedStage &&
        !!localStorageStage &&
        !localStorageStage?.isCompleted)
    ) {
      navigate('/');
    } else {
      markSuccessAsShown(Number(stageId));
      hasRun.current = true;
    }
  }, [stageId, localStorageStage, stageSteps]);

  return (
    <S.Container>
      <S.InnerContainer thin>
        <Row mt={7} jcT="center">
          <Col w={[4, 12, 12]} jc="center" mb={6}>
            <Icon
              icon="checklist2"
              color="tertiaryMid"
              width={85}
              height={115}
            />
          </Col>
          <Col w={[4, 12, 12]} jc="center">
            <T.H4 weight="bold" mb="6">
              Stage {stageId} of {totalStages} completed!
            </T.H4>
            <T.P color="neutralMain" mb="6" ta="center">
              You have successfully completed the{' '}
              <S.InlineParagraph weight="bold">
                {localStorageStage?.subtitle}
              </S.InlineParagraph>{' '}
              stage of the application process.
            </T.P>
            {stageId < totalStages ? (
              <T.P color="neutralMain" mb="6" ta="center">
                Click the “View next stage” button to see what’s next and
                remember to come back to the helper when it’s time to start the
                next step.
              </T.P>
            ) : (
              <T.P color="neutralMain" mb="6" ta="center">
                If you want to review any of the steps, you can see these at any
                time by returning to the home page.
              </T.P>
            )}
          </Col>
          <Col w={[4, 12, 6]} jc="center">
            {stageId < totalStages && (
              <Button
                mb={4}
                variant="primary"
                text={t(
                  'common.buttons.viewNextStage',
                  common.buttons.viewNextStage
                )}
                to={
                  publicOrg?.uniqueSlug
                    ? n.GENERAL.STAGE_ORG.replace(
                        ':uniqueSlug',
                        publicOrg.uniqueSlug
                      ).replace(':id', Number(stageId) + 1)
                    : n.GENERAL.STAGE.replace(':id', Number(stageId) + 1)
                }
              />
            )}
          </Col>
          <Col w={[4, 12, stageId < totalStages ? 6 : 12]} jc="center">
            <Button
              variant="tertiary"
              text={t('common.buttons.returnHome', common.buttons.returnHome)}
              to={n.GENERAL.HOME_REDIRECT.replace(':token', 'success')}
            />
          </Col>
        </Row>
      </S.InnerContainer>
    </S.Container>
  );
};

export default Success;
