import * as S from './style';
import * as T from '../../Typography';
import TextWithIcon from '../../TextWithIcon';
import { getStyles } from '../../../helpers';
import { useTranslation } from 'react-i18next';
import { common } from '../../../constants';
import { forwardRef } from 'react';

const imgMap = {
  1: '/images/stage-1.png',
  2: '/images/stage-2.png',
  3: '/images/stage-3.png',
  4: '/images/stage-4.png',
  5: '/images/stage-5.png',
};

const Stage = forwardRef(
  (
    { id = 1, themeKey, to = '/', title, text, stage, status, ...props },
    ref
  ) => {
    const { t } = useTranslation();
    const statusStyle = getStyles(status);

    const getStatusText = (status) => {
      switch (status) {
        case 'completed':
          return t('common.words.completed', common.words.completed);
        case 'inProgress':
          return t('common.words.inProgress', common.words.inProgress);
        default:
          return t(
            'common.words.todoClickToBegin',
            common.words.todoClickToBegin
          );
      }
    };

    return (
      <S.Wrapper to={to} {...props}>
        <S.CardHead status={status} bgImage={imgMap[id] || null} />
        <S.CardFooter bg={'neutralSurface'}>
          <S.StageP size="small" weight="bold" color="neutralMain">
            {title}
          </S.StageP>
          <T.H6 color="neutralMain" mb={2}>
            {text}
          </T.H6>
          <TextWithIcon
            mr={2}
            text={getStatusText(status)}
            textSize="xsmall"
            color={statusStyle.color}
            iconProps={{
              icon: statusStyle.icon,
              color: statusStyle.color,
            }}
            to={to}
          />
          <div ref={ref} />
        </S.CardFooter>
      </S.Wrapper>
    );
  }
);

export default Stage;
